import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService, PlanCost} from 'app/api-client';
import { AlertsUtilService } from 'app/services/alerts-util.service';
import { GeneralService } from 'app/services/general.service';
import { LoggedUserPlanService } from 'app/services/logged-user-plan.service';
import { PaymentConfirmedService } from 'app/services/payment-confirmed.service';
import { SubscriptionGiftService } from 'app/services/subscription-gift.service';
import { SubscriptionService } from 'app/services/subscription.service';
import { PageType } from 'app/services/subscription.service';
import { LoadingHelperService } from '../loading-helper/loading-helper.service';
import { ModalService } from '../modal.service';
import {hoslog} from '../../app.constants';
import {SubscriptionOrderWrapper} from '../../api-client/model/subscriptionOrderWrapper';
import {PayPalPaymentAcceptTermsComponent} from '../pay-pal-payment-accept-terms/pay-pal-payment-accept-terms.component';
import { v4 as uuidv4 } from 'uuid';
import kountSDK from '@kount/kount-web-client-sdk';
import {AppConfig} from '../../app.config';
import {environment} from '../../../environments/environment'

declare var Recurly: any;
declare var recurly: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class SubscriptionPaymentComponent implements OnInit {

  @Input() isUpdateBilling: boolean = null;

  @Input() embeddedForm: boolean = false;

  private title = "PAYMENT";
  private titleGift = "GIFT PAYMENT"; // TODO

  public checkPageType: any = PageType;
  public billingType: any = PlanCost.BillingTypeEnum;

  public hasCreditCardOnFile = false;

  private paypal: any = null;

  formSubmitted = false;
  termsAccepted = false;

  discountCode: string = null;

  sessionID: string;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public subscriptionService: SubscriptionService,
              public loggedUserPlanService: LoggedUserPlanService,
              public subscriptionGiftService: SubscriptionGiftService,
              private accountService: AccountService,
              private alertsUtilService: AlertsUtilService,
              private modalService: ModalService,
              public loadingHelperService: LoadingHelperService,
              private paymentConfirmedService: PaymentConfirmedService,
              private gs: GeneralService) {
    this.gs.setTitlePageAndMetadata(this.title);

    this.hasCreditCardOnFile = loggedUserPlanService.hasCreditCardOnFile;

    this.paypal = recurly.PayPal({
      display: {displayName: 'Hearts of Space Subscription'}
    });

    const me = this;
    this.paypal.on('error', function (err) {
      // console.log('Paypal error: ' + JSON.stringify(err));
      // err.code
      // err.message
      // [err.cause] if there is an embedded error
      if (err.message) {
        me.alertsUtilService.showAlert(err.message);
      } else {
        me.alertsUtilService.showAlert('An error happened while processing the paypal payment, please try again or contact support@hos.com');
      }
    });
    this.paypal.on('token', function (token) {
      hoslog('Paypal token: ' + JSON.stringify(token));
      // token.id
      if (token.id) {
        me.paymentTokenCreated(token.id)
      }
    });
  }

  ngOnInit() {
    this.initKount();

    if(this.isUpdateBilling!=null) {
      this.subscriptionService.init(PageType.UpdateBilling);
    } else {
      const isUpdateBilling = +this.activatedRoute.snapshot.queryParams['billing'] === 1;
      if (isUpdateBilling) {
        this.subscriptionService.init(PageType.UpdateBilling);
      } else {
        // if we aren't coming from a gift subscription request
        if (this.subscriptionService.pageType == null) {
          // go back
          this.router.navigate(['/subscription']);
        }
      }
    }

    if (this.subscriptionService.selectedPrice && this.subscriptionService.pageType != PageType.Gift && this.subscriptionService.pageType != PageType.UpdateBilling) {
      this.subscriptionService.refreshTotalPrices();
    }
  }

  initKount() {
    this.sessionID = uuidv4().replace(/-/g, '');
    // console.log('kount sessionID: ' + this.sessionID);
    kountSDK(AppConfig.kountConfig, this.sessionID);
  }

  selectDifferentCard() {
    this.hasCreditCardOnFile = false;
    /*setTimeout(() => {
      recurly.configure({publicKey:environment.recurlyPublicKey, parent: false}); // maurizio
    }, 1000);*/
  }

  paymentTokenCreated(token: string = undefined) {
    hoslog('paymentTokenCreated: ' + token);

    switch (this.subscriptionService.pageType) {
      case PageType.Create:
      case PageType.Edit:
        this.changePlan(token);
        break;
      case PageType.Gift:
        this.purchaseGiftPlan(token);
        break;
      case PageType.UpdateBilling:
        this.updateBillingInfo(token);
        break;
    }
  }

  changePlan(token: string = undefined) {
    this.loadingHelperService.startLoading();
    let couponCode = this.subscriptionService.discountCode$.value;
    let changePlanImmediately = this.subscriptionService.changePlanImmediately;
    this.accountService.changeCurrentPlan(this.subscriptionService.selectedPrice.planId, 'Recurly', token, couponCode ? couponCode : undefined, changePlanImmediately)
      .subscribe((response: SubscriptionOrderWrapper) => {
        // console.log('response = ' + JSON.stringify(response));
        // refresh current plan and redirect to the home
        this.loggedUserPlanService.refreshPlan();
        this.loadingHelperService.loadingOK();
        this.paymentConfirmedService.setLastPaymentOrderInfo(response, changePlanImmediately);
        this.router.navigate(['/subscription', 'payment', 'confirmation']); // payment confirmation page
      }, err => {
        // console.log('err = ' + JSON.stringify(err));
        this.loadingHelperService.loadingKO(err);
        this.alertsUtilService.showErrorAlert(err);
      });
  }

  purchaseGiftPlan(token: string) {
    hoslog('purchaseGiftPlan: ' + token);
    this.loadingHelperService.startLoading();
    this.accountService.purchaseGiftPlan(this.subscriptionService.selectedPrice.planId, token,
      this.subscriptionGiftService.recipientInfo.name, this.subscriptionGiftService.recipientInfo.email,
      this.subscriptionGiftService.recipientInfo.gift_date, this.subscriptionGiftService.recipientInfo.message)
      .subscribe(response => {
        // console.log('response = ' + JSON.stringify(response));
        this.loadingHelperService.loadingOK();
        this.router.navigate(['/'], {queryParams: {giftCreated: true}}) ;
      }, err => {
        // console.log('err = ' + JSON.stringify(err));
        this.loadingHelperService.loadingKO(err);
        this.alertsUtilService.showErrorAlert(err);
      });
  }

  private updateBillingInfo(token: string) {
    hoslog('updateBillingInfo: ' + token);
    this.loadingHelperService.startLoading();
    this.accountService.updateBillingInfo(token)
      .subscribe(response => {
        // console.log('response = ' + JSON.stringify(response));
        this.loadingHelperService.loadingOK();
        // this.router.navigate(['/account'], {queryParams: {billingInfoUpdated: true}});
        // TODO refresh page
        window.location.reload();

      }, err => {
        // console.log('err = ' + JSON.stringify(err));
        this.loadingHelperService.loadingKO(err);
        this.alertsUtilService.showErrorAlert(err);
      });
  }

  payWithPaypal() {
    if (this.paypal) {
      this.paypal.start();
    }
  }

  payWithCardOnFileClicked() {
    this.formSubmitted = true;
    if (this.termsAccepted) {
      this.paymentTokenCreated();
    }
  }

  public showPayWithPaypalDialog() {
    let modal$ = this.modalService.create(PayPalPaymentAcceptTermsComponent, {
      ok: (response) => {
        hoslog("OK: " + JSON.stringify(response));
      },
      cancel: (response) => {
        hoslog("cancel: " + JSON.stringify(response));

      },
      closeModal: (response) => {
        hoslog("CloseModal: " + JSON.stringify(response));
      }
    });

    modal$.subscribe((ref) => {
      let i = ref.instance as PayPalPaymentAcceptTermsComponent;
      i.paypal = this.paypal;
      this.router.events.subscribe((val) => {
        ref.destroy();
      });
    });
  }
}
