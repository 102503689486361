<div class="recurly-form">

<section>
  <form id="recurlyForm">
    <input type="hidden" name="recurly-token" data-recurly="token"/>
    <!--<input type="hidden" name="session_id" [value]="sessionID" />-->

    <div class="row form">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <label for="first_name">First Name on Credit Card</label><br>
              <input type="text" id="first_name" data-recurly="first_name">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <label for="last_name">Last Name on Credit Card</label><br>
              <input type="text" id="last_name" data-recurly="last_name">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <label for="number">Credit Card Number</label><br>
              <div id="number" data-recurly="number"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-4">
              <label for="month">Expiration Month</label><br>
              <div id="month" data-recurly="month"></div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <label for="year">Expiration Year</label><br>
              <div id="year" data-recurly="year"></div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-4">
              <label for="cvv">Security code</label><br>
              <div id="cvv" data-recurly="cvv"></div>
            </div>
          </div>
          <div class="row top-margin-row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <label for="phone">Phone Number (optional, for customer service use only)</label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <input type="text" id="phone" data-recurly="phone">
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <label for="cc_billing_address">Billing Address</label><br>
              <input type="text" id="cc_billing_address" data-recurly="address1">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <label for="cc_billing_address_line_2">Address Line 2 (optional)</label><br>
              <input type="text" id="cc_billing_address_line_2" data-recurly="address2">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <label for="cc_country">Country</label><br>
              <select id="cc_country" data-recurly="country">
                <option value="{{country.code}}" [selected]="country.code == 'US'" *ngFor="let country of countryList">{{country.name}}</option>
              </select>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <label for="state">State / Province</label><br>
              <input type="text" id="state" data-recurly="state">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6">
              <label for="cc_city">City</label><br>
              <input type="text" id="cc_city" data-recurly="city">
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
              <label for="cc_zip_code">Zip Code</label><br>
              <input type="text" id="cc_zip_code" data-recurly="postal_code">
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container-fluid-2 hos-checkbox" style="margin-top: 15px;">
                <input type="checkbox" id="termsAccepted" [(ngModel)]="termsAccepted" [ngModelOptions]="{standalone: true}" (change)="onTermsAcceptedChanged()">
                <label class="hos-checkbox-label" for="termsAccepted" [ngClass]="{'error': (isFormSubmitted() && !isFormValid() && !termsAccepted)}">
                  I accept the <a [routerLink]="['/pages/terms-and-conditions']" target="_blank">Terms &amp; Conditions</a>, <a [routerLink]="['/pages/service-details']" target="_blank">Service Details</a> and <a [routerLink]="['/pages/privacy-policy']" target="_blank">Privacy Policy</a>
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="row form-footer" [ngClass]="{'embedded': embeddedForm}">
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="container-fluid">
          <div class="card-wrapper">
            <img src="/assets/images/cards.png">
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 button-container">
        <div class="error-message" *ngIf="isFormSubmitted() && !isFormValid()">
          Please complete the mandatory fields in the form and try again.

          <section id="errors"></section>

        </div>

        <button type="submit" id="recurlyButton" name="recurlyButton" [ngClass]="{'embedded': embeddedForm}" [disabled]="!submitEnabled">{{submitEnabled ? (isUpdateBilling ? 'UPDATE CARD' : 'FINISH') : 'LOADING'}}</button>
      </div>
    </div>
  </form>

  <!--<a (click)="reconfigure()">Reconfigure</a>-->
</section>
</div>
