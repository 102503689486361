/**
 * HOS API
 *
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 *
 *
 *
 */

import {Album} from './Album';
import {Artist} from './Artist';
import {HosItem} from "./HosItem";


export interface Track extends HosItem {
  /**
   * Track id
   */
  id: number;

  /**
   * Title
   */
  title: string;

  /**
   * Artists
   */
  artists: Array<Artist>;

  /**
   * Duration
   */
  duration: number;

  /**
   * Popularity
   */
  popularity?: number;

  /**
   * Album
   */
  album?: Album;

  /**
   * Start position in stream
   */
  startPositionInStream?: number;

  type?: HosItem.TypeEnum;


  // Dynamic
  idx?: number;
}

/*export namespace Track {
    export enum TypeEnum {
        Program = <any> 'program',
        Track = <any> 'track',
        Album = <any> 'album'
    }
}*/
