import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {HosPlayerService} from '../../services/player/hos-player.service';
import {HosCurrentPlayState} from '../../services/player/hos-player';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {AlertsUtilService} from '../../services/alerts-util.service';
import {AppConstants, hoslog} from '../../app.constants';
import {LoggedUserSettingsService} from '../../api-client/helper/logged-user-settings.service';
import {LoginHelperService} from '../../api-client/helper/login-helper.service';
import {MixpanelService} from '../../services/mixpanel.service';
import {PermissionContextActionsEnum} from '../../api-client';
import {SleepData} from '../../shared/sleep-panel/sleepPanel.component';
import {HosPlayItem, PageReferral} from '../../services/player/hos-play-item';
import {Router} from '@angular/router';
import {SleepTimerService, SleepTimerType} from '../../services/sleep-timer.service';
import {VisibilityService} from '../../services/visibility.service';
import {HosQueue, HosQueueChannel, HosQueuePlaylist, QueueType} from '../../services/player/hos-queue';
import {PlayerVolumeService} from '../../services/player/player-volume.service';
import {StopTimerService} from '../../services/stop-timer.service';
import {TextUtils} from '../../shared/utils/text-utils';
import {Subject} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']/*,
  changeDetection: ChangeDetectionStrategy.OnPush*/
})
export class PlayerComponent implements OnInit, OnDestroy {
  private htmlElementId = 'hosplayer';

  private _isPlaying = false;

  public volume = 50;

  public songLength = 1500;
  public currentSongPoint = 0;
  private currentSongLastDraggedValue = 0;
  private progressLength = 0;
  private step = 0;
  public isSleepPanelOpen = false;
  private styleBlockMainTrack;
  private styleBlockVolumeTrack;
  private stylesTypes = ['-webkit-slider-runnable-track', '-moz-range-track'];
  private mouseMoveSubject = new Subject<number>();
  private mouseMoveObservable = this.mouseMoveSubject.asObservable();

  // UI variables
  currentItem: HosPlayItem = null;
  currentQueue: HosQueue = null;

  // Subscriptions

  // private readyObsSub;
  private statusObsSub;
  // private mutedObsSub;
  private volumeObsSub;
  private durationChangedObsSub;
  private playingTimeUpdatedObsSub;
  // private seekingObsSub;
  // private seekedObsSub;
  private voiceoverObsSub;
  private repeatObsSub;
  private playerErrorObsSub;

  private playerUiActive = true;

  showTrackInfo = false;
  descriptionTracks = null;
  public tracksList = []
  public currentTrack;
  public currentTrackNumber = 0;
  public albumsList = []
  timerDiv;
  timerLine;

  constructor(private _el: ElementRef,
              public renderer: Renderer2,
              public hosPlayerService: HosPlayerService,
              private alertsUtilService: AlertsUtilService,
              private loginHelperService: LoginHelperService,
              private loggedUserSettingsService: LoggedUserSettingsService,
              private hotkeysService: HotkeysService,
              private mixpanelService: MixpanelService,
              public sleepTimerService: SleepTimerService,
              private changeDetectorRef: ChangeDetectorRef,
              private visibilityService: VisibilityService,
              private playerVolumeService: PlayerVolumeService,
              private router: Router,
              private stopTimerService: StopTimerService) {

    visibilityService.pageVisibility$.subscribe(isPageVisible => {
      this.playerUiActive = isPageVisible;
    });

    this.hosPlayerService.currentItemObs.subscribe(
      (currentItem: any) => {
        hoslog('PlayerComponent: currentItem updated');
        this.currentItem = currentItem;
        if (currentItem._program) {
          const albums = currentItem._program.albums;
          let index = 1;
          if (this.tracksList.length === 0 || this.albumsList[0].id !== albums[0].id) {
            this.albumsList = JSON.parse(JSON.stringify(albums));
            this.tracksList = [];
          }
          if (this.tracksList.length === 0 && this.albumsList.length > 0) {
            this.tracksList = (this.albumsList.map((album) => album.tracks.map((track) => ({
              id: track.id,
              index: index++,
              title: track.title,
              startPositionInStream: track.startPositionInStream
            })))).flat();
          }
        }
        this.refreshUi();
      }
    );

    this.hosPlayerService.currentQueueObs.subscribe(
      (currentQueue) => {
        hoslog('PlayerComponent: currentQueue updated');
        this.currentQueue = currentQueue;
        this.refreshUi();
      }
    );

  }

  refreshUi() {
    // this.changeDetectorRef.markForCheck();
    // this.changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.progressLength = this._el.nativeElement.getElementsByClassName('player-progress')[0].getBoundingClientRect().width;
    this.descriptionTracks = <HTMLElement>document.getElementById('description-tracks');

    // Handle time bar position on window resize
    const self = this;
    window.onresize = function () {
      self.progressLength = self._el.nativeElement.getElementsByClassName('player-progress')[0].getBoundingClientRect().width;
      self.onDurationChanged(self.songLength);
      self.refreshTimeBar(self.currentSongLastDraggedValue);
    };

    this.styleBlockMainTrack = document.createElement('style');
    this.styleBlockVolumeTrack = document.createElement('style');
    document.getElementsByClassName('player_container')[0].appendChild(this.styleBlockMainTrack);
    document.getElementsByClassName('player_container')[0].appendChild(this.styleBlockVolumeTrack);


    this.hosPlayerService.init(this.htmlElementId);
    const me = this;
    // subscriptions to observable
    this.playerErrorObsSub = this.hosPlayerService.player.playerErrorObs.subscribe(
      function (x) {
        hoslog('playerErrorObs next: ' + JSON.stringify(x));
        me.onPlayerError(x);
      },
      function (err) {
        hoslog('playerErrorObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('playerErrorObs Completed');
      });

    /*this.readyObsSub = this.hosPlayerService.player.readyObs.subscribe(
      function (x) {
        hoslog('readyObs next: ' + x);
      },
      function (err) {
        hoslog('readyObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('readyObs Completed');
      });*/

    this.statusObsSub = this.hosPlayerService.player.statusObs.subscribe(
      function (x: HosCurrentPlayState) {
        hoslog('statusObs next: ' + HosCurrentPlayState[x].toString());
        me.onStatusChanged(x);
      },
      function (err) {
        hoslog('statusObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('statusObs Completed');
      });

    /*this.mutedObsSub = this.hosPlayerService.player.mutedObs.subscribe(
      function (x) {
        hoslog('mutedObs next: ' + x);
        //me.isMuted = x;
      },
      function (err) {
        hoslog('mutedObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('mutedObs Completed');
      });*/

    this.volumeObsSub = this.hosPlayerService.player.volumeObs.subscribe(
      function (x) {
        // hoslog('volumeObs next: ' + x);
        const volume = x * 100;
        me.refreshVolumeBar(volume);
      },
      function (err) {
        hoslog('volumeObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('volumeObs Completed');
      });

    this.durationChangedObsSub = this.hosPlayerService.currentItemDurationChangedObs.subscribe(
      function (x) {
        hoslog('durationChangedObs next: ' + x);
        me.onDurationChanged(x);
      },
      function (err) {
        hoslog('durationChangedObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('durationChangedObs Completed');
      });

    this.playingTimeUpdatedObsSub = this.hosPlayerService.currentItemPlayingTimeUpdatedObs.subscribe(
      (x) => {
        // hoslog('playingTimeUpdatedObs next: ' + x);
        me.setCurrentTime(x);
      },
      function (err) {
        hoslog('playingTimeUpdatedObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('playingTimeUpdatedObs Completed');
      });

    /*this.seekingObsSub = this.hosPlayerService.player.seekingObs.subscribe(
      function (x) {
        hoslog('seekingObs next: ' + x);
        // me.setCurrentTime(x);
      },
      function (err) {
        hoslog('seekingObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('seekingObs Completed');
      });

    this.seekedObsSub = this.hosPlayerService.player.seekedObs.subscribe(
      function (x) {
        hoslog('seekedObs next: ' + x);
        // me.setCurrentTime(x);
      },
      function (err) {
        hoslog('seekedObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('seekedObs Completed');
      });*/

    this.voiceoverObsSub = this.hosPlayerService.voiceoverObs.subscribe(
      function (x) {
        hoslog('voiceoverObs next: ' + x);
        // me.voiceoverOn = x;
      },
      function (err) {
        hoslog('voiceoverObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('voiceoverObs Completed');
      });

    /*this.repeatObsSub = this.hosPlayerService.repeatObs.subscribe(
      function (x) {
        hoslog('repeatObs next: ' + x);
        //me.repeatOn = x;
      },
      function (err) {
        hoslog('repeatObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('repeatObs Completed');
      });*/

    // setup hotkeys

    // Play/Pause
    this.hotkeysService.add(new Hotkey(['p', 'space'], (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey p: play/pause');
      this.play();
      return false; // Prevent bubbling
    }));

    // Mute toggle
    this.hotkeysService.add(new Hotkey('m', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey p: mute toggle');
      this.mute();
      return false; // Prevent bubbling
    }));

    // Rewind
    this.hotkeysService.add(new Hotkey('left', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey left: rewind');
      this.doRewind();
      return false; // Prevent bubbling
    }));

    // Forward
    this.hotkeysService.add(new Hotkey('right', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey right: forward');
      this.doForward();
      return false; // Prevent bubbling
    }));

    // Previous track
    this.hotkeysService.add(new Hotkey('meta+left', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey meta+left: Previous track');
      this.doPreviousTrack();
      return false; // Prevent bubbling
    }));

    // Next track
    this.hotkeysService.add(new Hotkey('meta+right', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey meta+right: Next track');
      this.doNextTrack();
      return false; // Prevent bubbling
    }));

    // Voiceover toggle
    this.hotkeysService.add(new Hotkey('v', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey v: Voiceover toggle');
      this.voiceoverClicked();
      return false; // Prevent bubbling
    }));

    // Volume up
    this.hotkeysService.add(new Hotkey('up', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey up: Volume up');
      const percVolume = (this.hosPlayerService.player.volume * 100) + 10;
      this.setVolume(percVolume);
      return false; // Prevent bubbling
    }));

    // Volume down
    this.hotkeysService.add(new Hotkey('down', (event: KeyboardEvent): boolean => {
      hoslog('Typed hotkey down: Volume down');
      const percVolume = (this.hosPlayerService.player.volume * 100) - 10;
      this.setVolume(percVolume);
      return false; // Prevent bubbling
    }));
    this.mouseMoveObservable.pipe(throttleTime(200)).subscribe(x => this.mouseMoveThrottledEvent(x));
  }

  ngOnDestroy() {
    // this.readyObsSub.unsubscribe();
    this.statusObsSub.unsubscribe();
    // this.mutedObsSub.unsubscribe();
    this.volumeObsSub.unsubscribe();
    this.durationChangedObsSub.unsubscribe();
    this.playingTimeUpdatedObsSub.unsubscribe();
    // this.seekingObsSub.unsubscribe();
    // this.seekedObsSub.unsubscribe();
    this.voiceoverObsSub.unsubscribe();
    this.repeatObsSub.unsubscribe();

    this.hosPlayerService.shutdown();
    this.mouseMoveSubject.unsubscribe();
  }

  private onStatusChanged(newStatus: HosCurrentPlayState) {
    hoslog('onStatusChanged = ' + HosCurrentPlayState[newStatus].toString());
    if (
      // x == HosCurrentPlayState.buffering ||
      newStatus == HosCurrentPlayState.playing
    ) {
      this._isPlaying = true;
    } else {
      this._isPlaying = false;
      if (newStatus == HosCurrentPlayState.idle) {
        // reset the time bar
        this.setCurrentTime(0);
      }
    }
  }

  private onPlayerError(error: any) {
    if (error && error.errorCode && error.errorName) {
      hoslog('onPlayerError: ', error.errorCode, error.errorName, error.errorDescription);
      let msg = AppConstants.clientErrors.STREAMING_SERVER_TIMEOUT.errorDescription;
      if (error.errorCode == 4) {
        msg = AppConstants.clientErrors.NO_STREAM_FILE.errorDescription;
      } else if (error.errorDescription) {
        msg = error.message;
      }
      this.alertsUtilService.showAlert(msg);
      this.refreshUi();
      // this.alertsUtilService.showErrorAlert(error);
    }
  }

  get currentPlayerStatus(): HosCurrentPlayState {
    return this.hosPlayerService.player.statusObs.getValue();
  }

  play() {
    const currentQueue = this.hosPlayerService.currentQueueObs.value;
    if (currentQueue && currentQueue.getCurrentItem() != null) {
      this.userInteracted();
      if (this.currentPlayerStatus == HosCurrentPlayState.idle) {
        this.mixpanelService.track('playerPlay');
        this.hosPlayerService.reloadLastItem();
      } else if (this.currentPlayerStatus == HosCurrentPlayState.playing) {
        this.mixpanelService.track('playerPause');
        this.hosPlayerService.pause();
      } else if (this.currentPlayerStatus == HosCurrentPlayState.paused) {
        this.mixpanelService.track('playerPlay');
        this.hosPlayerService.play();
      }
      this.refreshUi();
    }
  }

  private reloadCurrentItem() {
    this.hosPlayerService.forceReloadLastItem(this.currentSongPoint);
  }

  mute() {
    this.userInteracted();
    // this.isMuted = !this.isMuted;
    this.mixpanelService.track('playerMute');
    this.hosPlayerService.muteToggle();
  }

  // BUTTONS

  doRepeat() {
    this.userInteracted();
    if (this.checkTracksPermissions()) {
      this.mixpanelService.track('playerRepeat');
      this.hosPlayerService.repeatToggle();
    }
  }

  doShuffle() {
    this.userInteracted();
    if (this.checkTracksPermissions()) {
      this.mixpanelService.track('playerShuffle');
      this.hosPlayerService.shuffleToggle();
    }
  }

  doPreviousTrack() {
    this.userInteracted();
    if (this.checkTracksPermissions()) {
      // hoslog("doPreviousTrack clicked");
      this.mixpanelService.track('playerPrevTrack');
      if (this.currentSongPoint <= 3) {
        hoslog('previous track');
        this.hosPlayerService.previousTrack();
      } else {
        hoslog('seek');
        this.seek(0);
      }
    }
  }

  testReset() {
    this.userInteracted();
    this.hosPlayerService.resetStartPosition();
  }

  doRewind() {
    this.userInteracted();
    // hoslog("doRewind clicked");
    if (this.checkTracksPermissions()) {
      this.mixpanelService.track('playerRewind');
      this.hosPlayerService.rewind();

      // this.refreshTimeBar(this.currentSongLastDraggedValue);
    }
  }

  doForward() {
    this.userInteracted();
    // hoslog("doForward clicked");
    if (this.checkTracksPermissions()) {
      this.mixpanelService.track('playerForward');
      this.hosPlayerService.forward();
    }
  }

  doNextTrack() {
    this.userInteracted();
    // hoslog("doNextTrack clicked");
    if (this.checkTracksPermissions()) {
      this.mixpanelService.track('playerNextTrack');
      this.hosPlayerService.nextTrack();
    }
  }

  private checkTracksPermissions(sendAlert = true): boolean {
    const hasTracksSkippablePermission = this.loggedUserSettingsService.hasPermissionAndAction(AppConstants.PermissionTracksSkippable, PermissionContextActionsEnum.On);
    const tracksSkippableAllowed = this.loginHelperService.isLoggedIn$.value && hasTracksSkippablePermission;
    if (!tracksSkippableAllowed) {
      const msg = AppConstants.clientErrors.NO_TRACK_SKIPPABLE.errorDescription;
      this.alertsUtilService.showAlert(msg);
    }
    return tracksSkippableAllowed;
  }

  // VOLUME

  volumeMoved(ev) {
    this.userInteracted();
    const volumeTrackBar = document.getElementById('volumeTrackBar');

    const perc = ev.target.value;
    this.setVolume(perc);
  }

  setVolume(percVolume: number) {
    this.hosPlayerService.setVolume(percVolume);
  }

  refreshVolumeBar(percVolume: number) {
    if (!this.playerVolumeService.isAnimating) {
      this.volume = percVolume;
      let str = '';
      for (let i = 0; i < this.stylesTypes.length; i++) {
        str += '#volumeTrackBar::' + this.stylesTypes[i] + '{background-size:' + percVolume + '% 100%}';
      }
      this.styleBlockVolumeTrack.textContent = str;
    }
  }

  // VOICE OVER

  voiceoverClicked() {
    this.userInteracted();
    const hasVoiceOverPermission = this.loggedUserSettingsService.hasPermissionAndAction(AppConstants.PermissionVoiceoverControl, PermissionContextActionsEnum.On);
    const voiceoverAllowed = this.loginHelperService.isLoggedIn$.value && hasVoiceOverPermission;
    if (voiceoverAllowed) {
      this.mixpanelService.incrementUserProperty('Voiceover');
      this.mixpanelService.track('playerVoiceover');
      this.hosPlayerService.voiceoverToggle();
      this.reloadCurrentItem();
    } else {
      const msg = AppConstants.clientErrors.NO_VOICEOVER.errorDescription;
      this.alertsUtilService.showAlert(msg);
    }
  }

  // SLEEP TIMER

  /**
   * Handle open/close sleep timer panel
   * @param shouldOpen
   */
  sleepTimerOpen(shouldOpen?: boolean) {
    this.userInteracted();
    this.mixpanelService.incrementUserProperty('Sleeptimer');
    this.mixpanelService.track('playerSleepTimer');
    this.isSleepPanelOpen = shouldOpen;
  }

  /**
   * Triggered when sleep timer has been set
   * sleepTimer come in seconds
   * @param data
   */
  setSleepTimer(data: SleepData) {
    this.userInteracted();
    // data - here should be used for timer logic
    // Example of SleepData object: { sleepTimer: 43200, playToStreamEnd: true }

    // sleep timer panel should be closed when duration value received
    this.sleepTimerOpen(false);

    this.sleepTimerService.enableSleepTimer(data.playToStreamEnd ? SleepTimerType.EndOfStream : SleepTimerType.Timed, data.sleepTimer);
  }

  canSleepTimer() {
    this.sleepTimerOpen(false);
    this.sleepTimerService.cancelSleepTimer();
  }

  // SEEK

  public seek(time: number) {
    this.mixpanelService.track('playerSeek');
    this.hosPlayerService.seek(time);
  }

  private setCurrentTimeAndSeek(value: number) {
    this.setCurrentTime(value);
    this.seek(value);
  }

  private setCurrentTime(value: number) {
    this.currentSongPoint = value;
    this.refreshTimeBar(value);
  }

  private onDurationChanged(duration) {
    const currentTimeThumbWidth = 12;
    this.songLength = duration;
    // To have correct visual position of currentTimeThumb we should compensate its width in step
    this.step = (this.progressLength - currentTimeThumbWidth) / this.songLength; // step px per sec of song
  }

  currentTimeMoved(ev) {
    if (this.checkTracksPermissions(false)) {
      const value = ev.target.value;
      this.currentSongLastDraggedValue = +value;
      this.refreshTimeBar(value);
    }
  }

  currentTimeMovedInput(ev) {
    const timerDivLeftPos = ev.target.value * this.step;
    this.renderer.setStyle(this.timerLine, 'width', timerDivLeftPos + 'px');
    this.renderer.setStyle(this.timerDiv, 'left', timerDivLeftPos + 'px');
  }

  currentEventMove(ev) {
    this.renderer.setStyle(this.descriptionTracks, 'left', ev.pageX - 75 + 'px');
    const valueHover = +this.calcSliderPos(ev).toFixed(2);
    this.mouseMoveSubject.next(valueHover)
  }

  calcSliderPos(e) {
    return (e.offsetX / e.target.clientWidth) * parseInt(e.target.getAttribute('max'), 10);
  }

  refreshTimeBar(value) {
    // don't draw ui in background
    if (!this.playerUiActive) { return; }

    // let popupDiv = <HTMLElement>document.getElementById('currentTimePopup');
    this.timerDiv = <HTMLElement>document.getElementById('currentTimeThumb');
    this.timerLine = <HTMLElement>document.getElementById('currentProgressLine');
    // let popupDivWidth = (popupDiv.getBoundingClientRect().width) ? popupDiv.getBoundingClientRect().width : 205;
    // let leftPos = value * this.step - popupDivWidth / 2;
    // let correlation = (popupDivWidth / 2) - (this.progressLength - value * this.step);
    // if (correlation > -10) {
    //   leftPos = leftPos - Math.abs(correlation);
    // }
    const timerDivLeftPos = value * this.step;
    this.renderer.setStyle(this.timerDiv, 'left', timerDivLeftPos + 'px');
    if (timerDivLeftPos > 10) {
      this.renderer.setStyle(this.timerLine, 'width', timerDivLeftPos + 'px');
    } else {
      this.renderer.setStyle(this.timerLine, 'width', '10px');
    }

    // highlight part of bar that are already gone
    // let songTrackBar = document.getElementById('songTrackBar');
    // let perc = ~~(value / this.songLength * 100),
    let str = '';

    for (let i = 0; i < this.stylesTypes.length; i++) {
      str += '#songTrackBar::' + this.stylesTypes[i] + '{background-size: ' + Math.round(timerDivLeftPos) + 'px 100%}';
    }
    this.styleBlockMainTrack.textContent = str;
  }

  moveTimeFinished() {
    // used the setTimeout because this function do not need runs parallel with currentTimeMoved()
    setTimeout(() => {
      if (this.checkTracksPermissions()) {
        this.seek(+this.currentSongLastDraggedValue);
      }
    }, 100)
  }

  convertSecToTime(seconds: number) {
    let min, sec;
    min = Math.floor(seconds / 60);
    sec = seconds - min * 60;

    return this.timeToStr(min) + ':' + this.timeToStr(sec);
  }

  timeToStr(t: number) {
    const time = Math.floor(t);
    let res;
    if (time < 10) {
      res = '0' + time.toString();
    } else if (time === 0) {
      res = time.toString() + '0';
    } else {
      res = time.toString();
    }

    return res;
  }

  get isPlaying(): boolean {
    return this._isPlaying;
  }

  set isPlaying(value: boolean) {
    this._isPlaying = value;
    this.refreshUi();
  }

  userInteracted(): any {
    this.stopTimerService.reset();
  }

  getCurrentItemQueueUrl(): string {
    let resStr = null;
    switch (this.currentQueue.getQueueType()) {
      case QueueType.ThisWeekProgram:
      case QueueType.SingleProgram:
      case QueueType.SingleAlbum:
        resStr = this.currentItem.getItemUrl();
        break;
      case QueueType.Playlist:
        const playlist = (this.currentQueue as HosQueuePlaylist).getPlaylist();
        resStr = '/playlists/details/' + playlist.id;
        break;

      case QueueType.Channel:
        const channel = (this.currentQueue as HosQueueChannel).getChannel();
        resStr = '/channels/details/' + channel.id + '/' + TextUtils.slugify(channel.name);
        break;
      case QueueType.Empty:
        resStr = null;
        break;
    }
    // resStr += debug;
    return resStr;
  }

  metadataLine1Clicked(currentItem: HosPlayItem) {
    const currentItemQueueUrl = this.getCurrentItemQueueUrl();
    if (currentItemQueueUrl) {
      this.router.navigateByUrl(currentItemQueueUrl);
    }
  }

  metadataLine2Clicked(currentItem: HosPlayItem) {
    if (currentItem.getItemUrl()) {
      this.router.navigateByUrl(currentItem.getItemUrl());
    }
  }

  getCurrentItemMetadataType(): string {
    // const debug = this.currentQueue.getQueueTypeStr() + ' - ' + this.currentItem.getType().toString() + ' - ' + PageReferral[this.currentItem.getPageReferral()];
    let resStr = '';
    switch (this.currentQueue.getQueueType()) {
      case QueueType.ThisWeekProgram:
        resStr = 'THIS WEEK\'S PROGRAM';
        break;
      case QueueType.SingleProgram:
        resStr = 'PROGRAMS';
        break;
      case QueueType.SingleAlbum:
        resStr = 'ALBUMS';
        break;
      case QueueType.Playlist:
        resStr = 'PLAYLIST: ' + (this.currentQueue as HosQueuePlaylist).getPlaylist().name;
        break;
      case QueueType.Channel:
        resStr = 'CHANNEL: ' + (this.currentQueue as HosQueueChannel).getChannel().name;
        break;
      case QueueType.Recent:
        resStr = 'RECENT';
        break;
      case QueueType.Empty:
        resStr = '';
        break;
    }
    // resStr += debug;
    return resStr;
  }

  getControlNotAvailableMessage(controlName: string): string {
    // const debug = this.currentQueue.getQueueTypeStr() + ' - ' + this.currentItem.getType().toString() + ' - ' + PageReferral[this.currentItem.getPageReferral()];
    let resStr = controlName + ' is not available for ';
    switch (this.currentQueue.getQueueType()) {
      case QueueType.ThisWeekProgram:
      case QueueType.SingleProgram:
        resStr += 'Programs';
        break;
      case QueueType.SingleAlbum:
        resStr += 'Albums';
        break;
      case QueueType.Playlist:
        resStr += 'Playlists';
        break;
      case QueueType.Channel:
        resStr += 'Channels';
        break;
      case QueueType.Empty:
        resStr += 'this item';
        break;
    }
    // resStr += debug;
    return resStr;
  }

  mouseMoveThrottledEvent(width: number) {
    this.currentTrack = [...this.tracksList].reverse().find((currentValue) => currentValue.startPositionInStream <= width);
    this.currentTrackNumber = [...this.tracksList].findIndex((currentValue) => currentValue == this.currentTrack) + 1;
  }
}

