
export const environment = {
  production: true,
  analyticsEnabled: true,
  // ewr1-QcWDBpz4nOHQCYKjYDDX6M
  // recurlyPublicKey: 'ewr1-SweL8BqglmLJLKoT0NwNW2', // test
  // recurlyPublicKey: 'ewr1-SgzdcEUsjulqmWOKnWysCQ', // dev
  recurlyPublicKey: 'ewr1-x7LvNCV2O1Ej8ZpabMRFtT', // prod
  recurlySubDomain: 'hos',
  apiConfig: {
    publicAuthorization: "d2Vic2l0ZTpBdXIwcmEh",  // website:Aur0ra!
    clientId: "hos.web.1.0",
    // basePathPrefix: "https://staging-api.hos.com",
    basePathPrefix: "https://staging.hos.com/a",
    // basePath: "http://hosapi.bayinteractive.com/hos/api/v1"
    // basePath: "https://d20inl010nksuk.cloudfront.net/hos/api/v1"
    // basePath: "https://staging-api.hos.com/api/v1"
    basePath: "https://staging.hos.com/a/api/v1"
  },
  oauth: {
    // issuer: 'https://staging-api.hos.com/oauth/token',
    issuer: 'https://staging.hos.com/a/oauth/token',
    // tokenEndpoint: 'https://staging-api.hos.com/oauth/token'
    tokenEndpoint: 'https://staging.hos.com/a/oauth/token'
  },
  mixpanelConfig: {
    url: "https://api.mixpanel.com/track/",
    token: "237da9b5fe198a02e85a1b926eff0e09"
  },
  baseWebsitePath: "https://staging.hos.com",
  // basePathImageUrl: 'https://staging-api.hos.com/api/v1/images-repo',
  basePathImageUrl: 'https://staging.hos.com/a/api/v1/images-repo',
  fakePlayer: false,
  showLogs: false,
  playerDebugLog: false,
  sonosClientId: 'hos-sonos-app-test-13-client',
  kountConfig: {
    client_id: '100909',
    environment: 'TEST',
    isSinglePageApp: true,
    isDebugEnabled: true,
  },
  recaptchaV3SiteKey: '6LeLxLAqAAAAAL0t9ao8XRp1igUDU5VRA4ICtzn6'
};


/*export const environment = {
  production: true,
  apiConfig: {
    publicAuthorization: "d2Vic2l0ZTpBdXIwcmEh",  // website:Aur0ra!
    clientId: "hos.web.1.0",
    // basePath: "http://hosapi.bayinteractive.com/hos/api/v1"
    // basePath: "https://d20inl010nksuk.cloudfront.net/hos/api/v1"
    basePath: "http://hosv4api-env-2.hm4dwn5s2a.us-west-2.elasticbeanstalk.com/api/v1"
  },
  oauth: {
    issuer: 'http://hosv4api-env-2.hm4dwn5s2a.us-west-2.elasticbeanstalk.com/oauth/token',
    tokenEndpoint: 'http://hosv4api-env-2.hm4dwn5s2a.us-west-2.elasticbeanstalk.com/oauth/token'
  },
  mixpanelConfig: {
    url: "https://api.mixpanel.com/track/",
    token: "WRONG31afbe66e00a312026e89592cedcc1d0"
  },
  basePathImageUrl: 'http://hosv4api-env-2.hm4dwn5s2a.us-west-2.elasticbeanstalk.com/api/v1/images-repo',
  imagesUseOldServlet: true,
  fakePlayer: false
};*/
