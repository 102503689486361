import {Injectable, OnDestroy} from '@angular/core';
import {PlayerApiService} from '../../api-client/api/player-api.service';
import {ProgramsService} from '../../api-client/api/programs.service';
import {HosItem} from '../../api-client/model/HosItem';
import {HosCurrentPlayState, HosPlayerImpl} from './hos-player';
import {AlbumsService} from '../../api-client/api/albums.service';
import {HosQueue, HosQueueChannel, HosQueueEmpty, HosQueueSingleProgram, QueueType} from './hos-queue';
import {PlayTokenWrapper} from '../../api-client/model/PlayTokenWrapper';
import {HosPlayAlbum, HosPlayItem, HosPlayItemEmpty, HosPlayProgram, PageReferral} from './hos-play-item';
import {Track} from '../../api-client/model/Track';
import {AppConstants, hoslog} from '../../app.constants';
import {LoggedUserBlockedItemsService} from '../logged-user-blocked-items.service';
import {AlertsUtilService} from '../alerts-util.service';
import {LoginHelperService} from '../../api-client/helper/login-helper.service';
import 'rxjs-compat/add/observable/of';
import {LoggedUserSettingsService} from '../../api-client/helper/logged-user-settings.service';
import {AccountService, Channel, ChannelsService, StreamingQualityEnum} from '../../api-client';
import {Settings} from '../../api-client/model/Settings';
import {HosPlayerStorageService} from './hos-player-storage.service';
import {TwpPingResult, UnregisteredUsersStreamingCheckService} from '../unregistered-users-streaming-check.service';
import * as dayjs from 'dayjs';
import {SleepTimerService} from '../sleep-timer.service';
import {LoggedUserPlanService} from '../logged-user-plan.service';
import {SubSink} from 'subsink';
import {PlayerVolumeService} from './player-volume.service';
import {MixpanelService} from '../mixpanel.service';
import {TrackingUtils} from '../../shared/utils/tracking.utils';
import {VoiceoverStatus} from './hos-player.utils';
import {VoiceoverStorageService} from '../voiceover-storage.service';
import * as duration from 'dayjs/plugin/duration';
import {BehaviorSubject, of} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import TypeEnum = HosItem.TypeEnum;
import {ChannelsListService} from '../channels-list.service';

// This service is used by all the components to load an item into the player
@Injectable()
export class HosPlayerService implements OnDestroy {

  _player: HosPlayerImpl;

  voiceoverObs: BehaviorSubject<VoiceoverStatus>;
  loadingQueueIdObs: BehaviorSubject<string> = new BehaviorSubject<string>(''); // Queue = group of items
  loadingItemIdObs: BehaviorSubject<string> = new BehaviorSubject<string>('');  // it's easier to compare this to see if the item is playing
  loadingTrackIdObs: BehaviorSubject<number> = new BehaviorSubject<number>(0);  // it's easier to compare this to see if the item is playing
  currentQueueObs: BehaviorSubject<HosQueue> = new BehaviorSubject<HosQueue>(new HosQueueEmpty()); // Queue = group of items
  currentItemObs: BehaviorSubject<HosPlayItem> = new BehaviorSubject<HosPlayItem>(new HosPlayItemEmpty()); // Item
  currentItemIdObs: BehaviorSubject<string> = new BehaviorSubject<string>('');  // it's easier to compare this to see if the item is playing
  currentItemPlayingTimeUpdatedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0);  // it's easier to compare this to see if the item is playing
  currentItemDurationChangedObs: BehaviorSubject<number> = new BehaviorSubject<number>(0);  // it's easier to compare this to see if the item is playing
  currentTrackObs: BehaviorSubject<Track> = new BehaviorSubject<Track>(null); // track is a sub part of the item
  currentTrackIdObs: BehaviorSubject<number> = new BehaviorSubject<number>(0);  // it's easier to compare this to see if the item is playing

  private subs = new SubSink();

  private lastPlayTimeStarted: Date = null;
  private lastPingTimeSent: Date = null;
  private pingCount = 1;
  private lastPlayingTimeUpdated = 0;
  private secondsPlayed = 0;

  private lastHosPlayItemInfoTrackId: number = null;

  private rewinding = false; // used by the subscriber of playingTimeUpdatedObs

  constructor(private playerApiService: PlayerApiService,
              private programsService: ProgramsService,
              private albumsService: AlbumsService,
              private loginHelperService: LoginHelperService,
              private loggedUserSettingsService: LoggedUserSettingsService,
              private accountService: AccountService,
              private channelsService: ChannelsService,
              private loggedUserBlockedItemsService: LoggedUserBlockedItemsService,
              private loggedUserPlanService: LoggedUserPlanService,
              private hosPlayerStorageService: HosPlayerStorageService,
              private mixpanelService: MixpanelService,
              private unregisteredUsersStreamingCheckService: UnregisteredUsersStreamingCheckService,
              private sleepTimerService: SleepTimerService,
              private playerVolumeService: PlayerVolumeService,
              private voiceoverStorageService: VoiceoverStorageService,
              private channelsListService: ChannelsListService,
              private alertsUtilService: AlertsUtilService) {
    this._player = new HosPlayerImpl();
    dayjs.extend(duration);
    this.voiceoverObs = new BehaviorSubject<VoiceoverStatus>(voiceoverStorageService.getVoiceoverStatus());
  }

  init(htmlElementId: string) {
    let options = new Map<string, string>();
    options.set('HtmlElementId', htmlElementId);
    this._player.setup(options);

    this.restoreSavedVolume();

    this.lastPingTimeSent = new Date();

    let me = this;

    const isLoggedIn = this.loginHelperService.isLoggedIn$.value;
    if (isLoggedIn) {
      // wait for the user settings
      this.preloadFromUserSettings();
    } else {
      // put the this week program
      this.preloadThisWeekProgram();
    }

    this.subs.sink = this.loggedUserSettingsService.streamingQualityObs.subscribe(quality => {
      if (this._player) {
        switch (quality) {
          case StreamingQualityEnum.StreamingQualityBest:
            this._player.setStreamingQuality(-1);
            break;
          case StreamingQualityEnum.StreamingQualityNormal:
            this._player.setStreamingQuality(3);
            break;
          case StreamingQualityEnum.StreamingQualityEconomy:
            this._player.setStreamingQuality(2);
            break;
        }
      }
    });

    this.subs.sink = this.loginHelperService.isLoggedIn$.subscribe(
      isLoggedIn => {
        if (isLoggedIn) {
          // refresh the play token
          hoslog('Refreshing play token...');
          this.refreshPlayToken();
        } else {
          // if (me._player.statusObs.value === HosCurrentPlayState.loading || me._player.statusObs.value === HosCurrentPlayState.playing) {
          // hoslog('Logging out...');
          this.clear();
          // }
        }
      }
    );

    this.subs.sink = this.loggedUserPlanService.refreshPlan$.subscribe(userPlan => {
      // The plan has changed
      // refresh the play token
      hoslog('Refreshing play token...');
      this.refreshPlayToken();
    });

    this.subs.sink = this._player.durationChangedObs.subscribe(
      x => {
        let durationValue = x;
        let hosPlayItem = me.currentItemObs.value;
        if (hosPlayItem && hosPlayItem.getDurationOverride()) {
          durationValue = hosPlayItem.getDurationOverride();
        }

        this.currentItemDurationChangedObs.next(durationValue);
      }
    );

    this.subs.sink = this._player.playingTimeUpdatedObs.subscribe(
      x => {
        hoslog('Updating play item info at: ' + x);
        let startPositionValue = x;

        const newSecondsPlayed = x - this.lastPlayingTimeUpdated;
        this.secondsPlayed += newSecondsPlayed;
        this.lastPlayingTimeUpdated = x;
        hoslog('secondsPlayed: ' + this.secondsPlayed);

        let hosPlayItem = me.currentItemObs.value;
        let hosPlayItemInfo = hosPlayItem.updatePlayItemInfo(x);

        if (hosPlayItem && hosPlayItem.getStartPositionOverride() != null) {
          startPositionValue = x - hosPlayItem.getStartPositionOverride(); // offset
          if (startPositionValue < 0) {
            startPositionValue = 0;
          }
          if (startPositionValue > this.currentItemDurationChangedObs.value) {
            hoslog('Overridden start position > overridden duration: force stopping the track');
            this._player.trackFinished();
          }
        }
        this.currentItemPlayingTimeUpdatedObs.next(startPositionValue);

        //let hosPlayItemInfoId = me.currentItemIdObs.value;
        // update currentTrackObs and currentTrackIdObs
        if (hosPlayItemInfo != null && (me.lastHosPlayItemInfoTrackId == null || hosPlayItemInfo.track.id != me.lastHosPlayItemInfoTrackId)) {
          hoslog('Updating currentTrack');
          if (hosPlayItemInfo.track != null && me.loggedUserBlockedItemsService.isBlocked(hosPlayItemInfo.track)) {
            // track blocked, skipping
            hoslog('track blocked, skipping');
            if (me.rewinding) {
              me.rewinding = false;
              me.rewind();
            } else {
              me.forward();
            }
          } else {
            // new track is fine
            if (hosPlayItemInfo.track) {
              me.lastHosPlayItemInfoTrackId = hosPlayItemInfo.track.id;
              me.currentTrackObs.next(hosPlayItemInfo.track);
              me.currentTrackIdObs.next(hosPlayItemInfo.track.id);
            }
          }
        }

        // play ping

        // Force the ping immediately
        const currentPlayerStatus = me.player.statusObs.value;
        // const isLoggedIn = me.loginHelperService.isLoggedIn$.value;
        // console.log('Ping count: ' + me.pingCount);
        // console.log('currentPlayerStatus: ' + currentPlayerStatus);
        // console.log('isLoggedIn: ' + isLoggedIn);

        let forcePing = false;
        if (currentPlayerStatus === HosCurrentPlayState.playing && /*isLoggedIn && */me.pingCount == 1) {
          forcePing = true;
          me.pingCount += 1;
        }

        if (forcePing || me.lastPingTimeSent == null || ((new Date()).getTime() - me.lastPingTimeSent.getTime()) > AppConstants.PlayerPingTimeInterval) {
          hoslog('Play PING: ' + ((new Date()).getTime() - me.lastPingTimeSent.getTime()));
          hoslog('Play PING: pingCount: ' + me.pingCount);
          hoslog('Play PING: Force: ' + forcePing);
          hoslog('Play PING: lastPingTimeSent: ' + me.lastPingTimeSent);

          // Sleep timer check
          if (me.sleepTimerService.pingSleepTimerCheck()) {
            this.animatedPause();
          }

          let playItem = me.currentQueue.getCurrentItem();
          // hoslog('currentQueue: ' + JSON.stringify(me.currentQueue));

          let secondsSinceLastCall = this.secondsPlayed; // ((new Date()).getTime() - me.lastPingTimeSent.getTime()) / 1000;
          hoslog('secondsSinceLastCall = ' + secondsSinceLastCall);
          let playTokenWrapper = playItem.getPlayTokenWrapper();

          // hoslog('currentPlayTokenWrapper: ' + JSON.stringify(playTokenWrapper));

          if (playItem && playTokenWrapper) {
            me.lastPingTimeSent = new Date();
            const pageReferralRefId = null; // This parameter is intended for future use, for now it is null because it is ignored in the API.
            me.playerApiService.ping(playItem.getType().toString(), playItem.getId(), x, secondsSinceLastCall, playTokenWrapper.playToken, PageReferral[playItem.getPageReferral()].toString(), pageReferralRefId, me.pingCount)
              .subscribe(
                res => {
                  hoslog('playerApiService.ping ok');

                  // Reset the seconds for the next ping
                  me.secondsPlayed = 0;

                  me.pingCount += 1;

                  if (me.isPlayingForMoreThen12Hours()) {
                    this.animatedPause();
                  }
                },
                error => {
                  if(error && error.errorCode && error.errorCode===1002) {
                    hoslog('playerApiService.ping warning: twp mins become zero');
                    // Reset the seconds for the next ping
                    me.secondsPlayed = 0;

                    me.pingCount += 1;

                    if (me.isPlayingForMoreThen12Hours()) {
                      this.animatedPause();
                    }

                    me.alertsUtilService.showAlertError(error.errorName);

                  } else {
                    hoslog('playerApiService.ping error: ' + JSON.stringify(error));

                    me.pingCount += 1;

                    if (me.isPlayingForMoreThen12Hours()) {
                      this.animatedPause();
                      return;
                    }

                    const isLoggedIn = me.loginHelperService.isLoggedIn$.value;
                    const isTwp = playItem.getPageReferral() === PageReferral.twp;
                    if (isTwp && !isLoggedIn) { // special way to deal with unlogged users for twp
                      const result = me.unregisteredUsersStreamingCheckService.manageUnloggedTwpPing(playItem.getHosItem().id);
                      if (result === TwpPingResult.Upsell) {
                        // Stopping the playback
                        this.animatedPause();
                        // Showing the error
                        me.alertsUtilService.showAlertError('INSUFFICIENT_RIGHTS_TWS');
                      } else if (result === TwpPingResult.NagMsg) {
                        // Showing the msg only
                        me.alertsUtilService.showAlertError('UNREGISTERED_TWP_4_PLUS_TIMES');
                      }
                      // else Continue without error
                    } else {
                      let isSessionHijackedError = false;
                      if (error && error.errorName === 'ON_SESSION_HIJACKED'
                        || (error.error && error.error.errorName === 'ON_SESSION_HIJACKED')) {
                        isSessionHijackedError = true;
                      }

                      if (me.pingCount > AppConstants.MinimumPingCountToAllowContinueToTheEnd && !isSessionHijackedError) {
                        hoslog('Continuing to the end of the stream');
                      } else {
                        // Stopping the playback
                        if (!forcePing) {
                          this.animatedPause();
                          this.resetPosition();
                        }
                        // Resetting the ping count to zero so we avoid the next ping to be a force ping
                        // If we set it to 1 it will be
                        me.pingCount = 0;
                        // Showing the error
                        me.onPingError(error);
                      }
                    }
                  }
                });
          } else {
            // hoslog('playItem: ' + JSON.stringify(playItem));
            hoslog('playTokenWrapper: ' + JSON.stringify(playTokenWrapper));
            hoslog('Problem: ping stopped!');
          }
        }

        // TODO Check if the current queue item has a virtual duration enabled (es. Playlist track queue), in that case check if we need to end the current play
        // const currentPlayItem = me.currentQueue.getCurrentItem();
        // if () {

        // }
      },
      err => {
        hoslog('playingTimeUpdatedObs Error: ' + JSON.stringify(err));
      },
      () => {
        hoslog('playingTimeUpdatedObs Completed');
      });

    this.subs.sink = this._player.trackFinished$.subscribe(
      value => {
        // console.log('trackFinished$ event');
        // check the case of unregistered users listening to the twp
        let playItem = me.currentQueue.getCurrentItem();
        const isLoggedIn = me.loginHelperService.isLoggedIn$.value;
        const isTwp = playItem.getPageReferral() === PageReferral.twp;
        if (isTwp && !isLoggedIn) { // special way to deal with unlogged users for twp
          me.unregisteredUsersStreamingCheckService.twpProgramForUnloggedEnded(playItem.getHosItem().id);
        }

        // Player logic

        // Sleep timer check
        if (me.sleepTimerService.trackFinishedSleepTimerCheck()) {
          this.animatedPause();
          return;
        }

        // Check if there is some more to play
        hoslog('Check if there is some more to play');
        me.nextTrack();
      }
    );

    this.subs.sink = this._player.statusObs.subscribe(
      function (newStatus: HosCurrentPlayState) {
        hoslog('statusObs next: ' + newStatus.toString());
        if (newStatus == HosCurrentPlayState.playing) {
          me.resetLastPlayTimeStarted();
        }
      },
      function (err) {
        hoslog('statusObs Error: ' + JSON.stringify(err));
      },
      function () {
        hoslog('statusObs Completed');
      });
  }

  resetPosition() {
    this.resetStartPosition();
    this.pingCount = 0;
  }

  get currentQueue(): HosQueue {
    return this.currentQueueObs.value;
  }

  refreshPlayToken() {
    if (this.currentQueue) {
      let playItem = this.currentQueue.getCurrentItem();
      if (playItem) {
        let playTokenWrapper = playItem.getPlayTokenWrapper();
        if (playTokenWrapper && playTokenWrapper.playToken) {
          this.playerApiService.refreshPlayToken(playTokenWrapper.playToken, playItem.getType().toString(), playItem.getId(), PageReferral[playItem.getPageReferral()].toString(), VoiceoverStatus[this.voiceover].toString())
            .subscribe(
              res => {
                hoslog('playerApiService.refreshPlayToken loaded: ' + JSON.stringify(res));
                // this.lastPingTimeSent = new Date(); // reset the ping date
                this.pingCount = 1; // reset ping count
                // this.unregisteredUsersStreamingCheckService.reset();
                this.setPlayTokenWrapper(res);
              },
              error => {
                hoslog('playerApiService.refreshPlayToken error: ' + JSON.stringify(error));
                this.clear();
              })
        }
      }
    }
  }

  clear(): any {
    // hoslog('clear');
    this.seek(0);
    this.animatedPause(); // this.player.pause();
    //this.loadingQueueIdObs.next('');
    //this.loadingItemIdObs.next('');
    //this.loadingTrackIdObs.next(0);
    this.currentQueueObs.next(new HosQueueEmpty()); // Queue = group of items
    this.currentItemObs.next(new HosPlayItemEmpty()); // Item
    this.currentItemIdObs.next('');  // it's easier to compare this to see if the item is playing
    //this.currentItemPlayingTimeUpdatedObs.next(0);
    //this.currentItemDurationChangedObs.next(0);
    this.currentTrackObs.next(null); // track is a sub part of the item
    this.currentTrackIdObs.next(0);  // it's easier to compare this to see if the item is playing

    //this.lastPlayTimeStarted = null;
    //this.lastPingTimeSent = null;
    //this.pingCount = 1;
    //this.lastHosPlayItemInfoTrackId = null;
    //this.rewinding = false;
  }

  private onPingError(error: any) {
    if (error) {
      hoslog('onPingError: ', error.errorCode, error.errorName, error.errorDescription);
      this.alertsUtilService.showErrorAlert(error);
      if (error.errorName === 'ON_SESSION_HIJACKED'
        || (error.error && error.error.errorName === 'ON_SESSION_HIJACKED')) {
        // clearing the player
        this.clear();
      }
    }
  }

  private onPlayError(error: any) {
    if (error) {
      hoslog('onPlayError: ', error.errorCode, error.errorName, error.errorDescription);
      if (error.errorCode && error.errorCode === 1003) {
        this.alertsUtilService.showAlert(error.errorDescription);
        this.clear();
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    //this.shutdown(); needed?
  }

  get player(): HosPlayerImpl {
    return this._player;
  }

  play(queue: HosQueue = null, track: Track = null, startPlaying = true, startPosition: number = null, forceReload = false) {
    if (queue != null) {
      // warning: if I also compare the queue, then I cannot pause a single program, started in a channel or playlist, from the program detail page
      if (!forceReload && this.currentQueue /*&& queue.getQueueUniqueId() == this.currentQueue.getQueueUniqueId()*/ && queue.getUniqueId() == this.currentQueue.getUniqueId()) { // Already loaded
        hoslog('Same queue');
        if (this._player.statusObs.getValue() == HosCurrentPlayState.idle) {
          hoslog('Reload queue');
          this.mixpanelService.track('PlayItem', TrackingUtils.getHosQueuePlayValue('load', queue));
          this.loadItem(queue.getCurrentItem(), track, startPlaying); // reload
        } else if (this._player.statusObs.getValue() == HosCurrentPlayState.playing) {
          if (track == null || track.id == this.currentTrackIdObs.value) {
            hoslog('Same queue + same track: pause');
            this.mixpanelService.track('PlayItem', TrackingUtils.getHosQueuePlayValue('pause', queue));
            this.animatedPause(); // this._player.pause();
          } else { // same queue but different track
            hoslog('Same queue + different track: seek');
            this.mixpanelService.track('PlayItem', TrackingUtils.getHosQueuePlayValue('trackSeek', queue));
            this.mixpanelService.incrementUserProperty('Played');
            this.seek(track.startPositionInStream);
          }
        } else if (this._player.statusObs.getValue() == HosCurrentPlayState.paused) {

          // check special case (channel)
          if(queue.getQueueType() === QueueType.Channel) {
            const queueChannelId = (queue as HosQueueChannel).getChannelId(); // get the current channel ID
            const firstItem = queue.getItems()[0] as HosPlayProgram; // get the first program of the queue

            if (firstItem.getOriginalProgram()['isForcedAdded']) {
              hoslog('isForcedAdded');
              hoslog('firstItemPlayTokenWrapper: ' + JSON.stringify(firstItem.getPlayTokenWrapper()));
              const currentChannel = this.getChannelByIdInternal(this.channelsListService.channels$.value, queueChannelId);
              currentChannel.programs.shift(); // remove the first item
              const newQueue = new HosQueueChannel(currentChannel); // create a new queue with the currentChannel (without the first item)
              this.silentQueueUpdate(newQueue); // update storage
              this.loadItem(newQueue.getCurrentItem()); // load the new current item (the first of the list)
              this.channelsListService.channels$.next(this.channelsListService.channels$.value);
            }
          }

          if (track == null || track.id == this.currentTrackIdObs.value) {
            hoslog('Same queue + same track: play');
            this.mixpanelService.track('PlayItem', TrackingUtils.getHosQueuePlayValue('play', queue));
            this.mixpanelService.incrementUserProperty('Played');
            this.animatedPlay(); // this._player.play();
          } else { // same queue but different track
            this.mixpanelService.track('PlayItem', TrackingUtils.getHosQueuePlayValue('trackSeek', queue));
            hoslog('Same queue + different track: seek');
            this.seek(track.startPositionInStream);
          }
        }
      } else { // New queue
        // restart the queue from the beginning (in case the queue has been already started before)
        // queue.restart();
        this.loadingQueueIdObs.next(queue.getQueueUniqueId());
        this.currentQueueObs.next(queue);
        this.mixpanelService.track('PlayItem', TrackingUtils.getHosQueuePlayValue('load', queue));
        this.loadItem(queue.getCurrentItem(), track, startPlaying, startPosition);
      }
    } else {
      // just play action on current loaded element
      this.mixpanelService.track('PlayItem', TrackingUtils.getHosQueuePlayValue('play', queue));
      this.mixpanelService.incrementUserProperty('Played');
      this.animatedPlay();
    }
  }

  getChannelByIdInternal(channels: Channel[], channelId: number): Channel {
    if (channels !== null && channels.length > 0) {
      return channels.find(ch => ch.id === channelId);
    }
    return undefined;
  }

  isPlaying(): boolean {
    return (this._player.statusObs.getValue() == HosCurrentPlayState.playing);
  }

  seek(time: number, checkForOverride = true) {
    hoslog('seek: ' + time);
    // Reset the lastPlayingTimeUpdated because we changed the position of the playback,
    // so we restart the seconds count from that position
    this.lastPlayingTimeUpdated = time;
    // if there is a startPositionOverride, I have to calculate the offset
    let startPositionValue = time;
    let hosPlayItem = this.currentItemObs.value;
    if (checkForOverride && hosPlayItem && hosPlayItem.getStartPositionOverride() != null) {
      startPositionValue = hosPlayItem.getStartPositionOverride() + time; // offset
    }
    this.animatedSeek(startPositionValue); // this._player.seek(startPositionValue);
  }

  reloadLastItem() {
    this.play(this.currentQueue);
  }

  forceReloadLastItem(startPosition: number = null) {
    const currentQueue = this.currentQueue;
    // this.clear();
    let currentPlayState = this._player.statusObs.value;
    let startPlaying = (currentPlayState === HosCurrentPlayState.playing || currentPlayState === HosCurrentPlayState.loading);
    this.play(currentQueue, null, startPlaying, startPosition, true);
  }

  private loadItem(playItem: HosPlayItem, track: Track = null, startPlaying = true, startPosition: number = null) {
    // hoslog('playItem: ' + JSON.stringify(playItem) + ', track: ' + JSON.stringify(track) + ', startPosition: ' + startPosition)

    if (startPlaying) {
      this._player.loadPreAudioItem(); // safari auto play strict policies hack
    }
    if (playItem != null) {
      let detailObservable = null;
      if (playItem.getType() == TypeEnum.Program) {
        // Program
        detailObservable = this.programsService.getProgramDetail(playItem.getId())
          .pipe(
            tap(
              completeProgram => {
                hoslog('getProgramDetail loaded'/* + JSON.stringify(res)*/);
                let completePlayItem = new HosPlayProgram(completeProgram, playItem.getPageReferral(), playItem.getIdx(), playItem.getPageReferralRefId());
                this.setCurrentTrack(completePlayItem);
              },
              error => {
                hoslog('getProgramDetail error: ' + JSON.stringify(error));
              })
          );
      } else if (playItem.getType() == TypeEnum.Album) {
        // Album
        detailObservable = this.albumsService.getAlbumDetail(playItem.getId())
          .pipe(
            tap(
              completeAlbum => {
                hoslog('getAlbumDetail loaded'/* + JSON.stringify(res)*/);
                // TODO delete: temp to test the track rewind and forward properly
                /*completeAlbum.tracks.forEach((track: Track, index: number) => {
                  track.startPositionInStream = index * 120;
                  track.duration = 119;
                });*/
                // TODO delete: temp to test the track rewind and forward properly
                let completePlayItem = new HosPlayAlbum(completeAlbum, playItem.getIdx());
                this.setCurrentTrack(completePlayItem);
              },
              error => {
                hoslog('getAlbumDetail error: ' + JSON.stringify(error));
              })
          );
      } else if (playItem.getType() == TypeEnum.Track) {
        // The track is already completed
        detailObservable = of(playItem);
      }

      // completing call to get the stream url
      if (detailObservable) {
        hoslog('Set state to Loading...');
        // temporary, will be replaced with the complete item
        this.loadingItemIdObs.next(playItem.getUniqueId());
        if (track) {
          hoslog('Set state to Loading...track!');
          this.loadingTrackIdObs.next(track.id);
        } else if (playItem.getType() == TypeEnum.Track && playItem.getStartPositionOverride() != null && playItem.getStartPositionOverride() > 0) {
          hoslog('Track item with position override');
          if (playItem.getPlayItemInfo().track) {
            track = playItem.getPlayItemInfo().track;

            hoslog('Set state to Loading...track!');
            this.loadingTrackIdObs.next(track.id);
          }
        }
        this.player.changeStatus(HosCurrentPlayState.loading);
        const pageReferralRefId = playItem.getPageReferralRefId();
        detailObservable
          .pipe(
            mergeMap(res =>
              this.playerApiService.play(playItem.getType().toString(), playItem.getId(), PageReferral[playItem.getPageReferral()].toString(), pageReferralRefId, VoiceoverStatus[this.voiceover].toString(), playItem.getHosItem()?.title, undefined))
          )
          .subscribe(
            res => {
              hoslog('playerApiService.play loaded: ' + JSON.stringify(res));
              this.lastPingTimeSent = new Date(); // reset the ping date
              this.pingCount = 1; // reset ping count
              this.lastPlayingTimeUpdated = 0;
              this.secondsPlayed = 0; // reset the seconds counter
              this.unregisteredUsersStreamingCheckService.reset();
              this.setPlayTokenWrapper(res);
              this.playLoadedTrack(track, startPlaying);

              if (startPosition && startPosition > 0) {
                this.seek(startPosition);
              }
            },
            error => {
              hoslog('playerApiService.play error: ' + JSON.stringify(error));
              this.onPlayError(error);
            })
      }
    }
  }

  private setCurrentTrack(completePlayItem: HosPlayItem) {
    this.currentQueue.updateCurrentItemInfo(completePlayItem);
  }

  private setPlayTokenWrapper(playTokenWrapper: PlayTokenWrapper): void {
    this.currentQueue.setCurrentItemPlayToken(playTokenWrapper);
  }

  private playLoadedTrack(track: Track = null, startPlaying = true) {
    //this.currentTrack = completePlayItem;
    let completePlayItem = this.currentQueue.getCurrentItem();
    this.currentItemObs.next(completePlayItem);
    this.currentItemIdObs.next(completePlayItem.getUniqueId());

    let startPosition = 0;
    if (track != null) {
      startPosition = track.startPositionInStream;
    }
    this.animatedLoadItem(completePlayItem, startPosition, startPlaying); // this._player.loadItem(completePlayItem, startPosition, startPlaying);
  }

  get voiceover(): VoiceoverStatus {
    return this.voiceoverObs.value;
  }

  set voiceover(status: VoiceoverStatus) {
    this.voiceoverObs.next(status);
  }

  voiceoverToggle() {
    switch (this.voiceover) {
      case VoiceoverStatus.Off:
        this.voiceover = VoiceoverStatus.On;
        break;
      case VoiceoverStatus.On:
        this.voiceover = VoiceoverStatus.IntroOnly;
        break;
      case VoiceoverStatus.IntroOnly:
        this.voiceover = VoiceoverStatus.Off;
        break;
    }
    this.voiceoverStorageService.setVoiceoverStatus(this.voiceover);
  }

  get repeat(): boolean {
    let queue = this.currentQueueObs.value;
    return queue && queue.canRepeat() && queue.isRepeatEnabled();
  }

  set repeat(on: boolean) {
    let queue = this.currentQueueObs.value;
    if (queue && queue.canRepeat()) {
      queue.setRepeatStatus(on);
    }
  }

  get shuffle(): boolean {
    let queue = this.currentQueueObs.value;
    return queue && queue.canShuffle() && queue.isShuffleEnabled();
  }

  set shuffle(on: boolean) {
    let queue = this.currentQueueObs.value;
    if (queue && queue.canShuffle()) {
      queue.setShuffleStatus(on);
    }
  }

  repeatToggle() {
    this.repeat = !this.repeat;
  }

  shuffleToggle() {
    this.shuffle = !this.shuffle;
  }

  previousTrack() {
    if (this.currentQueueObs.value.canPrevious()) {
      let playItem = this.currentQueueObs.value.previous();
      let hosItem = playItem.getHosItem();

      // check if it's blocked
      if (hosItem != null && this.loggedUserBlockedItemsService.isBlocked(hosItem)) {
        this.previousTrack(); // try previous track
      } else {
        this.loadItem(playItem);
      }
    }
  }

  rewind() {
    hoslog('rewind clicked');
    /*let currentItemInfo = this.currentItemObs.value.getPlayItemInfo();
    if (currentItemInfo.prevTrackTime != null) {
      this.rewinding = true;
      this.seek(currentItemInfo.prevTrackTime);
    }*/
    /*let currentTime = this.currentSongPoint;
     currentTime -= 10; // back 10 seconds
     if (currentTime < 0) {
     currentTime = 0;
     }
     this.setCurrentTimeAndSeek(currentTime);*/
    let itemStartPoint = 0;
    const playItem = this.currentItemObs.value;
    const playItemInfo = playItem.getPlayItemInfo();
    if (playItem.getStartPositionOverride() != null) {
      hoslog('seek with override');
      itemStartPoint = playItem.getStartPositionOverride(); // offset
    } else {
      if (playItemInfo) {
        itemStartPoint = playItemInfo.fromTime;
      }
      let currentSongPoint = this.currentItemPlayingTimeUpdatedObs.value;
      if (playItemInfo && currentSongPoint <= (itemStartPoint + 3) && itemStartPoint != 0) {
        hoslog('rewind');
        itemStartPoint = playItemInfo.prevTrackTime;
      } else {
        hoslog('seek');
      }
    }

    this.rewinding = true;
    this.seek(itemStartPoint, false);

  }

  forward() {
    hoslog('forward clicked');
    let currentItemInfo = this.currentItemObs.value.getPlayItemInfo();
    if (currentItemInfo.nextTrackTime != null) {
      this.rewinding = false;
      this.seek(currentItemInfo.nextTrackTime + 1); // + 1 so it goes directly to the next track
    }
    /*let currentTime = this.currentSongPoint;
     currentTime += 10; // forward 10 seconds
     if (currentTime > this.songLength) {
     currentTime = this.songLength;
     }
     this.setCurrentTimeAndSeek(currentTime);*/

  }

  nextTrack() {
    if (this.currentQueueObs.value.canNext()) {
      let playItem = this.currentQueueObs.value.next();
      let hosItem = playItem.getHosItem();
      // check if it's blocked
      if (hosItem != null && this.loggedUserBlockedItemsService.isBlocked(hosItem)) {
        this.nextTrack(); // try next track
      } else {
        this.loadItem(playItem);
      }
    }
  }

  shutdown() {
    if (this.player) {
      this.player.shutdown();
    }
  }

  setVolume(percVolume: number) {
    if (percVolume > 100) {
      percVolume = 100;
    }
    this.player.volume = percVolume / 100;
    this.hosPlayerStorageService.setVolume(percVolume);
    this.player.volumeObs.next(percVolume / 100)
    if (percVolume > 0 && this.player.isMuted()) {
      this.player.muteToggle();
    }
  }

  muteToggle() {
    this.player.muteToggle();
  }

  getVoiceOverLabelFromStatus(status: VoiceoverStatus) {
    switch (this.voiceover) {
      case VoiceoverStatus.Off:
        return 'OFF';
      case VoiceoverStatus.On:
        return 'ON';
      case VoiceoverStatus.IntroOnly:
        return 'INTRO';
    }
  }

  private preloadFromUserSettings() {
    hoslog('preloadFromUserSettings');
    const sub = this.loggedUserSettingsService.loggedUserSettingsObs
      .subscribe(settings => {
        if (settings) {
          switch (settings.signInContent) {
            case Settings.SignInContentEnum.ChooseChannel:
              this.preloadChannel(settings.signInContentChannelId);
              break;
            case Settings.SignInContentEnum.ThisWeekShow:
              this.preloadThisWeekProgram();
              break;
            default:
              this.loadSavedStream();
              break;
          }
          sub.unsubscribe();
        }
      });
  }

  private preloadThisWeekProgram() {
    hoslog('preloadThisWeekProgram');
    this.programsService.getThisWeekProgram()
      .subscribe(
        res => {
          hoslog('getThisWeekProgram for player loaded' /*+ JSON.stringify(res)*/);
          if (res) {
            this.play(new HosQueueSingleProgram(res, PageReferral.twp), null, false);
            this.hosPlayerStorageService.readOnly = false; // can save from now on
          }
        },
        error => {
          hoslog('getThisWeekProgram for player error: ' + JSON.stringify(error));

        });
  }

  private preloadChannel(channelId: number) {
    hoslog('preloadChannel: ' + channelId);
    this.channelsService.getChannelDetail(channelId)
      .subscribe(
        res => {
          hoslog('getThisWeekProgram for player loaded' /*+ JSON.stringify(res)*/);
          if (res) {
            this.play(new HosQueueChannel(res), null, false);
            this.hosPlayerStorageService.readOnly = false; // can save from now on
          }
        },
        error => {
          hoslog('getThisWeekProgram for player error: ' + JSON.stringify(error));
          this.preloadThisWeekProgram();
        });
  }

  private loadSavedStream() {
    hoslog('loadSavedStream');

    const deserializedHosQueue = this.hosPlayerStorageService.getSavedQueue();

    // const savedQueueObj = new HosQueueSingleProgram(savedQueue['program']);
    // const savedQueueObj = Object.assign(Object.create(HosQueueSingleProgram.prototype), savedQueue); //(new HosQueueSingleProgram()).copy
    // hoslog('savedQueueObj: ' + savedQueueObj);
    if (deserializedHosQueue) {
      this.play(deserializedHosQueue.queue, null, false);
    }
    this.hosPlayerStorageService.readOnly = false; // can save from now on
  }

  private isPlayingForMoreThen12Hours(): boolean {
    // hoslog('isPlayingForMoreThen12Hours: lastPlayTimeStarted = ' + this.lastPlayTimeStarted);
    if (this.lastPlayTimeStarted) {
      const now = dayjs();
      const lastPlayTime = dayjs(this.lastPlayTimeStarted);
      const lastPlayTimeDifference = now.diff(lastPlayTime);
      const lastPlayTimeDuration = dayjs.duration(lastPlayTimeDifference).asSeconds();
      hoslog('Playing for ' + lastPlayTimeDuration + ' seconds');
      return (lastPlayTimeDuration > AppConstants.PlayerMaxPlayTimeInSeconds);
    }
    return false;
  }

  private resetLastPlayTimeStarted() {
    hoslog('resetLastPlayTimeStarted');
    this.lastPlayTimeStarted = new Date();
  }

  silentQueueUpdate(newQueue: HosQueue) {
    // hoslog('silentQueueUpdate... newQueue.getQueueUniqueId(): ' + newQueue.getQueueUniqueId());
    this.loadingQueueIdObs.next(newQueue.getQueueUniqueId());
    this.currentQueueObs.next(newQueue);
  }

  private restoreSavedVolume() {
    const percVolume = this.hosPlayerStorageService.getVolume();
    if (percVolume) {
      this.setVolume(percVolume);
    } else {
      this.setVolume(50);
    }
  }

  pause() {
    this.animatedPause();
  }

  private animatedPlay() {
    this.playerVolumeService.fadeIn(
      () => {
        this._player.play();
      },
      () => {
      }
    );
  }

  private animatedPause() {
    this.playerVolumeService.fadeOut(
      () => {
      },
      () => {
        this._player.pause();
      }
    );
  }

  private animatedSeek(time: number) {
    try {
      this.playerVolumeService.fadeOutIn(
        () => {
        },
        () => {
          this._player.seek(time);
        },
        () => {
        }
      );
    } catch (e) {

    }
  }

  private animatedLoadItem(playItem: HosPlayItem, startPosition: number, startPlaying = true) {
    try {
      this.playerVolumeService.fadeOutIn(
        () => {
        },
        () => {
          this._player.loadItem(playItem, startPosition, startPlaying);
        },
        () => {
        }
      );
    } catch (e) {

    }
  }

  resetStartPosition() {
    hoslog('reset clicked');
    let itemStartPoint = 0;

    this.rewinding = true;
    this.seek(itemStartPoint, false);
  }
}
