import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {AppConstants, Country} from '../../app.constants';
import * as jQuery from 'jquery';
import {AccountService} from '../../api-client';
import {environment} from '../../../environments/environment';

declare var recurly: any;

var formSubmitted = false;
var formValid = false;

// When a customer hits their 'enter' key while in a field
recurly.on('field:submit', function (event) {
  jQuery('#recurlyForm').submit();
});


// A simple error handling function to expose errors to the customer
function error(err) {
  formValid = false;
  jQuery('#errors').text('The following fields appear to be invalid: ' + err.fields.join(', '));
  jQuery('#recurlyButton').prop('disabled', false);
  jQuery.each(err.fields, function (i, field) {
    jQuery('[data-recurly=' + field + ']').addClass('error');
  });
}

@Component({
  selector: 'app-recurly-form',
  templateUrl: './recurly-form.component.html',
  styleUrls: ['./recurly-form.component.scss']
})
export class RecurlyFormComponent implements OnInit, AfterViewInit {
  public countryList: Country[] = AppConstants.countryList;

  @Input() sessionID: string = '';

  @Input() embeddedForm: boolean = false;

  @Input() isUpdateBilling: boolean = null;
  @Input('submitEnabled') submitEnabled = true;
  @Output('token') tokenEvent = new EventEmitter<string>();
  termsAccepted: boolean = false;

  constructor(private zone: NgZone) {
  }

  ngOnInit(): void {
    console.log('Received sessionID: ', this.sessionID);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.configureRecurly();
    }, 100);
  }

  configureRecurly(): void {
    this.termsAccepted = false;

    // Configure recurly.js
    // console.log('Configure recurly.js');
    recurly.configure({
      publicKey: environment.recurlyPublicKey,
      style: {
        all: {
          fontFamily: "Titillium Web"
        },
        number: {
          fontColor: '#ffffff',
          placeholder: ''
        },
        month: {
          fontColor: '#ffffff',
          placeholder: 'mm'
        },
        year: {
          fontColor: '#ffffff',
          placeholder: 'yy'
        },
        cvv: {
          fontColor: '#ffffff'
        }
      }
    });

    const me = this;

    // On form submit, we stop submission to go get the token
    jQuery('#recurlyForm').on('submit', function (event) {
      // console.log('event = ' + event);
      formSubmitted = true;

      // Prevent the form from submitting while we retrieve the token from Recurly
      event.preventDefault();

      // Reset the errors display
      jQuery('#errors').text('');
      jQuery('input').removeClass('error');
      jQuery('[data-recurly]').removeClass('error');


      // Disable the submit button
      // jQuery('#recurlyButton').prop('disabled', true);
      me.zone.run(() => {
        me.submitEnabled = false;
      });

      const form = this;

      // Now we call recurly.token with the form. It goes to Recurly servers
      // to tokenize the credit card information, then injects the token into the
      // data-recurly="token" field above
      // console.log(JSON.stringify(form));
      formValid = true; // put it to true to avoid showing the error while loading
      recurly.token(form, function (err, token) {

        me.zone.run(() => {
          me.submitEnabled = true;
        });

        // send any errors to the error function below
        if (err || !me.termsAccepted) {
          formValid = false; // show the error
          error(err);
        } else { // Otherwise we continue with the form submission
          if (token && token.id) {
            // alert('The token is: ' + token.id);
            // console.log('The token is: ' + token.id);

            // re-enable the button in case of error
            // jQuery('#recurlyButton').prop('disabled', false);

            me.tokenEvent.emit(token.id);
          } else {
            formValid = false; // show the error
          }
        }
      });

    });
  }

  isFormSubmitted() {
    return formSubmitted;
  }

  isFormValid() {
    return formValid;
  }

  reconfigure() {
    this.configureRecurly();
  }

  onTermsAcceptedChanged() {
    // console.log('termsAccepted = ' + this.termsAccepted);
  }
}
