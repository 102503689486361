/**
 * HOS API
 *
 * OpenAPI spec version: 1.0
 * Contact: info@bayinteractive.com
 *
 */
import {Injectable, Injector} from "@angular/core";
import {HosBaseService} from "./hos-base.service";

import {HttpRequest, HttpHeaders, HttpResponse, HttpEvent, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import "../rxjs-operators"
import {AppConstants} from "../../app.constants";;
import {AuthToken} from "../model/AuthToken";
import {FavoriteWrapper} from "../model/FavoriteWrapper";
import {HosItem} from "../model/HosItem";
import {PlanPayment} from "../model/PlanPayment";
import {SessionToken} from "../model/SessionToken";
import {Settings} from "../model/Settings";
import {User} from "../model/User";
import {UserPlan} from "../model/UserPlan";
import * as dayjs from 'dayjs';
import {RequestsUtils} from '../../shared/utils/requests-utils';
import {SubscriptionOrderWrapper} from '../model/subscriptionOrderWrapper';
import {SubscriptionPreview} from '../model/subscriptionPreview';
import {EmailPreferencesWrapper} from '../model/EmailPreferencesWrapper';
import {PaymentHistory} from '../model/PaymentHistory';
import {GenericUtils} from '../../shared/utils/generic.utils';
import {ActivationResponse} from '../model/ActivationResponse';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class AccountService extends HosBaseService {


  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Cancel subscription plan
   *
   */
  public cancelSubscriptionPlan(extraHttpRequestParams?: any): Observable<{}> {
    return this.cancelSubscriptionPlanWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Cancel subscription
   *
   */
  public cancelSubscription(subscriptionId: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.cancelSubscriptionWithHttpInfo(subscriptionId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Reactivate subscription
   *
   */
  public reactivateSubscription(subscriptionId: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.reactivateSubscriptionWithHttpInfo(subscriptionId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Change plan
   *
   * @param planId The plan id
   * @param paymentType The payment type (Recurly or InAppPurchase)
   * @param paymentData If paymentType &#x3D; Recurly, it&#39;s the payment token. If paymentType &#x3D; IOSInAppPurchase, it&#39;s the receipt data that has to be verified by the server
   * @param couponCode The coupon code
   * @param changePlanImmediately Try to change the plan immediately, if possible
   */
  public changeCurrentPlan(planId: number, paymentType: string, paymentData: string = undefined, couponCode: string = undefined, changePlanImmediately: boolean = undefined, extraHttpRequestParams?: any): Observable<SubscriptionOrderWrapper> {
    return this.changeCurrentPlanWithHttpInfo(planId, paymentType, paymentData, couponCode, changePlanImmediately, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Preview a plan change
   *
   * @param planId The plan id
   * @param paymentType The payment type (Recurly or InAppPurchase)
   * @param paymentData If paymentType &#x3D; Recurly, it&#39;s the payment token. If paymentType &#x3D; IOSInAppPurchase, it&#39;s the receipt data that has to be verified by the server
   * @param couponCode The coupon code
   * @param changePlanImmediately Try to change the plan immediately, if possible
   */
  public previewChangeCurrentPlan(planId: number, paymentType: string, paymentData: string = undefined, couponCode: string = undefined, changePlanImmediately: boolean = undefined, extraHttpRequestParams?: any): Observable<SubscriptionPreview> {
    return this.previewChangeCurrentPlanWithHttpInfo(planId, paymentType, paymentData, couponCode, changePlanImmediately, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Cancel HOS account
   *
   */
  public closeAccount(extraHttpRequestParams?: any): Observable<{}> {
    return this.closeAccountWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Reset password method
   * Send the password reset email (containing the reset token) to a non-logged user
   * @param email The email address of the account
   * @param newPassword The new password
   * @param resetToken The reset token sent to the user via email
   */
  public confirmPasswordReset(newPassword: string, resetToken: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.confirmPasswordResetWithHttpInfo(newPassword, resetToken, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Validate the reset password token
   * @param resetToken The reset token sent to the user via email
   */
  public validatePasswordResetToken(resetToken: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.validatePasswordResetTokenWithHttpInfo(resetToken, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Create account
   *
   * @param firstName The first name
   * @param lastName The last name
   * @param email The email address
   * @param password The password
   * @param phoneNumber The phone number
   */
  public createAccount(firstName: string, lastName: string, email: string, password: string, phoneNumber?: string, redemptionCode?: string, type?: string, linkCode?: string, hhid?: string, extraHttpRequestParams?: any): Observable<Object> {
    return this.createAccountWithHttpInfo(firstName, lastName, email, password, phoneNumber, redemptionCode, type, linkCode, hhid, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Activate
   *
   * @param token The token
   * @param captchaToken Captcha token
   * @param type The type
   * @param linkCode The linkCode
   * @param hhid The hhid
   */
  public activateAccount(token: string, captchaToken: string, type?: string, linkCode?: string, hhid?: string, extraHttpRequestParams?: any): Observable<ActivationResponse> {
    return this.activateWithHttpInfo(token, captchaToken, type, linkCode, hhid, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  public redeemGiftPlan(redemptionCode: string, extraHttpRequestParams?: any): Observable<Object> {
    return this.redeemGiftPlanWithHttpInfo(redemptionCode, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Get authentication token
   *
   * @param clientId The app-specific id
   */
  public getAuthToken(clientId: string, username: string, password: string, extraHttpRequestParams?: any): Observable<AuthToken> {
    return this.getAuthTokenWithHttpInfo(clientId, username, password, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  public getIsFirstRun(extraHttpRequestParams?: any): Observable<{}> {
    return this.getIsFirstRunWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  public setFirstRunDone(extraHttpRequestParams?: any): Observable<{}> {
    return this.setFirstRunDoneWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the blocked items
   *
   * @param itemType The item type
   */
  public getBlockedItems(itemType: string, extraHttpRequestParams?: any): Observable<HosItem[]> {
    return this.getBlockedItemsWithHttpInfo(itemType, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * View current plan
   *
   */
  public getCurrentPlan(platform?: string, extraHttpRequestParams?: any): Observable<UserPlan> {
    return this.getCurrentPlanWithHttpInfo(platform, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  public getCurrentMinutes(extraHttpRequestParams?: any): Observable<number> {
    return this.getCurrentMinutesWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  public getRecurlyHostedLoginToken(extraHttpRequestParams?: any): Observable<any> {
    return this.getRecurlyHostedLoginTokenWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the favorites
   *
   */
  public getFavorites(extraHttpRequestParams?: any): Observable<FavoriteWrapper> {
    return this.getFavoritesWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the payments for user
   *
   */
  public getPlanPayments(platform?: string, extraHttpRequestParams?: any): Observable<PlanPayment> {
    return this.getPlanPaymentsWithHttpInfo(platform, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the payments for user
   *
   */
  public getPaymentsHistory(platform?: string, extraHttpRequestParams?: any): Observable<PaymentHistory> {
    return this.getPaymentsHistoryWithHttpInfo(platform, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Exchange the authentication token for a session token
   *
   * @param authToken The auth token
   */
  public getSessionToken(authToken: string, extraHttpRequestParams?: any): Observable<SessionToken> {
    return this.getSessionTokenWithHttpInfo(authToken, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Return the settings
   *
   */
  public getSettings(extraHttpRequestParams?: any): Observable<Settings> {
    return this.getSettingsWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * View user details
   *
   */
  public getUserDetails(extraHttpRequestParams?: any): Observable<User> {
    return this.getUserDetailsWithHttpInfo(extraHttpRequestParams)
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Purchase gift plan
   *
   * @param planId The plan id
   * @param paymentToken Recurly payment token (the Gift Plan can only be purchased via Recurly)
   * @param phoneNumber The phone number
   * @param billingAddress The billing address
   * @param country The country
   * @param state The State/Province
   * @param city The city
   * @param zipCode The zip code
   * @param recipientName The recipient name
   * @param recipientEmail The recipient email
   * @param giftDate The gift date
   * @param billingAddressLine2 The billing address line 2 (optional)
   * @param message The message (optional)
   */
  public purchaseGiftPlan(planId: number, paymentToken: string, recipientName: string, recipientEmail: string, giftDate: Date, message?: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.purchaseGiftPlanWithHttpInfo(planId, paymentToken, recipientName, recipientEmail, giftDate, message, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  public updateBillingInfo(paymentToken: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.updateBillingInfoWithHttpInfo(paymentToken, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Reset password method
   * Send the password reset email (containing the reset token) to a non-logged user
   * @param email The email address of the account
   */
  public resetPassword(email: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.resetPasswordWithHttpInfo(email, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Set email preferences
   *
   * @param receiveHosWeeklyPlaylist Receive HoS weekly playlist
   * @param receiveHosNewsletter Receive HoS newsletter
   * @param receiveHosDiscountOffers Receive HoS discount offers
   */
  public setEmailPreferences(receiveHosWeeklyPlaylist?: boolean, receiveHosNewsletter?: boolean, receiveHosDiscountOffers?: boolean, extraHttpRequestParams?: any): Observable<EmailPreferencesWrapper> {
    return this.setEmailPreferencesWithHttpInfo(receiveHosWeeklyPlaylist, receiveHosNewsletter, receiveHosDiscountOffers, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Set hemisphere
   *
   * @param value The hemisphere
   */
  public setHemisphere(value: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.setHemisphereWithHttpInfo(value, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Set signin content
   *
   * @param value The value to set
   * @param channelId The channel id (required if value&#x3D;choose_channel)
   */
  public setSignInContent(value: string, channelId?: number, extraHttpRequestParams?: any): Observable<{}> {
    return this.setSignInContentWithHttpInfo(value, channelId, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Set startup page
   *
   * @param value The value to set
   */
  public setStartupPage(value: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.setStartupPageWithHttpInfo(value, extraHttpRequestParams)
      .pipe(
        map((response) => {
        if (response.status === 204) {
          return undefined;
        } else {
          return response.body;
        }
        })
      );
  }

  /**
   * Update contact info
   *
   * @param firstName The first name
   * @param lastName The last name
   * @param email The email address
   * @param phoneNumber The phone number
   */
  public updateContactInfo(firstName: string, lastName: string, email: string, phoneNumber?: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.updateContactInfoWithHttpInfo(firstName, lastName, email, phoneNumber, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }

  /**
   * Update the password
   *
   * @param oldPassword The old password
   * @param newPassword The new password
   */
  public updatePassword(oldPassword: string, newPassword: string, extraHttpRequestParams?: any): Observable<{}> {
    return this.updatePasswordWithHttpInfo(oldPassword, newPassword, extraHttpRequestParams)
      .pipe(
        map((response) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        })
      );
  }


  /**
   * Cancel subscription plan
   *
   */
  public cancelSubscriptionPlanWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/plan`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Cancel subscription
   *
   */
  public cancelSubscriptionWithHttpInfo(subscriptionId: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/recurly/subscriptions/${subscriptionId}/cancel`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [];

    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Reactivate subscription
   *
   */
  public reactivateSubscriptionWithHttpInfo(subscriptionId: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/recurly/subscriptions/${subscriptionId}/reactivate`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [];

    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Change plan
   *
   * @param planId The plan id
   * @param paymentType The payment type (Recurly or InAppPurchase)
   * @param paymentData If paymentType &#x3D; Recurly, it&#39;s the payment token. If paymentType &#x3D; IOSInAppPurchase, it&#39;s the receipt data that has to be verified by the server
   * @param couponCode The coupon code
   * @param changePlanImmediately Change plan immediately, if possible
   */
  public changeCurrentPlanWithHttpInfo(planId: number, paymentType: string, paymentData: string = undefined, couponCode: string = undefined, changePlanImmediately: boolean = undefined, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/plan`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'planId' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(planId)) {
      throw new Error('Required parameter planId was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'paymentType' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(paymentType)) {
      throw new Error('Required parameter paymentType was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'paymentData' is not null or undefined
    /*if (paymentData === null || paymentData === undefined) {
      throw new Error('Required parameter paymentData was null or undefined when calling changeCurrentPlan.');
    }*/
    // verify required parameter 'phoneNumber' is not null or undefined
    /*if (phoneNumber === null || phoneNumber === undefined) {
      throw new Error('Required parameter phoneNumber was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'billingAddress' is not null or undefined
    if (billingAddress === null || billingAddress === undefined) {
      throw new Error('Required parameter billingAddress was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'country' is not null or undefined
    if (country === null || country === undefined) {
      throw new Error('Required parameter country was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'state' is not null or undefined
    if (state === null || state === undefined) {
      throw new Error('Required parameter state was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'city' is not null or undefined
    if (city === null || city === undefined) {
      throw new Error('Required parameter city was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'zipCode' is not null or undefined
    if (zipCode === null || zipCode === undefined) {
      throw new Error('Required parameter zipCode was null or undefined when calling changeCurrentPlan.');
    }*/


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(planId)) {
      formParams = formParams.set('planId', planId.toString());
    }
    if (!GenericUtils.isUndefinedOrNull(paymentType)) {
      formParams = formParams.set('paymentType', paymentType);
    }
    if (!GenericUtils.isUndefinedOrNull(paymentData)) {
      formParams = formParams.set('paymentData', paymentData);
    }
    if (!GenericUtils.isUndefinedOrNull(couponCode)) {
      formParams = formParams.set('couponCode', couponCode);
    }
    if (!GenericUtils.isUndefinedOrNull(changePlanImmediately)) {
      formParams = formParams.set('changePlanImmediately', <any>changePlanImmediately);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Preview a plan change
   *
   * @param planId The plan id
   * @param paymentType The payment type (Recurly or InAppPurchase)
   * @param paymentData If paymentType &#x3D; Recurly, it&#39;s the payment token. If paymentType &#x3D; IOSInAppPurchase, it&#39;s the receipt data that has to be verified by the server
   * @param couponCode The coupon code
   * @param changePlanImmediately Change plan immediately, if possible
   */
  public previewChangeCurrentPlanWithHttpInfo(planId: number, paymentType: string, paymentData: string = undefined, couponCode: string = undefined, changePlanImmediately: boolean = undefined, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/plan/preview`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'planId' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(planId)) {
      throw new Error('Required parameter planId was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'paymentType' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(paymentType)) {
      throw new Error('Required parameter paymentType was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'paymentData' is not null or undefined
    /*if (paymentData === null || paymentData === undefined) {
      throw new Error('Required parameter paymentData was null or undefined when calling changeCurrentPlan.');
    }*/
    // verify required parameter 'phoneNumber' is not null or undefined
    /*if (phoneNumber === null || phoneNumber === undefined) {
      throw new Error('Required parameter phoneNumber was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'billingAddress' is not null or undefined
    if (billingAddress === null || billingAddress === undefined) {
      throw new Error('Required parameter billingAddress was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'country' is not null or undefined
    if (country === null || country === undefined) {
      throw new Error('Required parameter country was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'state' is not null or undefined
    if (state === null || state === undefined) {
      throw new Error('Required parameter state was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'city' is not null or undefined
    if (city === null || city === undefined) {
      throw new Error('Required parameter city was null or undefined when calling changeCurrentPlan.');
    }
    // verify required parameter 'zipCode' is not null or undefined
    if (zipCode === null || zipCode === undefined) {
      throw new Error('Required parameter zipCode was null or undefined when calling changeCurrentPlan.');
    }*/


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(planId)) {
      formParams = formParams.set('planId', planId.toString());
    }
    if (!GenericUtils.isUndefinedOrNull(paymentType)) {
      formParams = formParams.set('paymentType', paymentType);
    }
    if (!GenericUtils.isUndefinedOrNull(paymentData)) {
      formParams = formParams.set('paymentData', paymentData);
    }
    if (!GenericUtils.isUndefinedOrNull(couponCode)) {
      formParams = formParams.set('couponCode', couponCode);
    }
    if (!GenericUtils.isUndefinedOrNull(changePlanImmediately)) {
      formParams = formParams.set('changePlanImmediately', <any>changePlanImmediately);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Cancel HOS account
   *
   */
  public closeAccountWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [];


    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.DELETE, path, requestOptions);
  }

  /**
   * Reset password method
   * Send the password reset email (containing the reset token) to a non-logged user
   * @param email The email address of the account
   * @param newPassword The new password
   * @param resetToken The reset token sent to the user via email
   */
  public confirmPasswordResetWithHttpInfo(newPassword: string, resetToken: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/password/reset/confirm`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'newPassword' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(newPassword)) {
      throw new Error('Required parameter newPassword was null or undefined when calling confirmPasswordReset.');
    }
    // verify required parameter 'resetToken' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(resetToken)) {
      throw new Error('Required parameter resetToken was null or undefined when calling confirmPasswordReset.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];

    if (!GenericUtils.isUndefinedOrNull(newPassword)) {
      formParams = formParams.set('newPassword', newPassword);
    }
    if (!GenericUtils.isUndefinedOrNull(resetToken)) {
      formParams = formParams.set('resetToken', resetToken);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Validate the password reset token
   * @param resetToken The reset token sent to the user via email
   */
  public validatePasswordResetTokenWithHttpInfo(resetToken: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/password/reset/validate`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'resetToken' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(resetToken)) {
      throw new Error('Required parameter resetToken was null or undefined when calling confirmPasswordReset.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];

    if (!GenericUtils.isUndefinedOrNull(resetToken)) {
      formParams = formParams.set('resetToken', resetToken);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Create account
   *
   * @param firstName The first name
   * @param lastName The last name
   * @param email The email address
   * @param password The password
   * @param phoneNumber The phone number
   */
  public createAccountWithHttpInfo(firstName: string, lastName: string, email: string, password: string, phoneNumber?: string, redemptionCode?: string, type?: string, linkCode?: string, hhid?: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'firstName' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(firstName)) {
      throw new Error('Required parameter firstName was null or undefined when calling createAccount.');
    }
    // verify required parameter 'lastName' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(lastName)) {
      throw new Error('Required parameter lastName was null or undefined when calling createAccount.');
    }
    // verify required parameter 'email' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(email)) {
      throw new Error('Required parameter email was null or undefined when calling createAccount.');
    }
    // verify required parameter 'password' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(password)) {
      throw new Error('Required parameter password was null or undefined when calling createAccount.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(firstName)) {
      formParams = formParams.set('firstName', firstName);
    }
    if (!GenericUtils.isUndefinedOrNull(lastName)) {
      formParams = formParams.set('lastName', lastName);
    }
    if (!GenericUtils.isUndefinedOrNull(email)) {
      formParams = formParams.set('email', email);
    }
    if (!GenericUtils.isUndefinedOrNull(phoneNumber)) {
      formParams = formParams.set('phoneNumber', phoneNumber);
    }
    if (!GenericUtils.isUndefinedOrNull(password)) {
      formParams = formParams.set('password', password);
    }
    if (!GenericUtils.isUndefinedOrNull(redemptionCode)) {
      formParams = formParams.set('redemptionCode', redemptionCode);
    }

    if (!GenericUtils.isUndefinedOrNull(type)) {
      formParams = formParams.set('type', type);
    }

    if (!GenericUtils.isUndefinedOrNull(linkCode)) {
      formParams = formParams.set('linkCode', linkCode);
    }

    if (!GenericUtils.isUndefinedOrNull(hhid)) {
      formParams = formParams.set('hhid', hhid);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

  public redeemGiftPlanWithHttpInfo(redemptionCode: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/gift-plan/redeem`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'redemptionCode' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(redemptionCode)) {
      throw new Error('Required parameter redemptionCode was null or undefined when calling redeemGiftPlan.');
    }

    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(redemptionCode)) {
      formParams = formParams.set('redemptionCode', redemptionCode);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

  public getIsFirstRunWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/first-run`;

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let produces: string[] = [];

    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  public setFirstRunDoneWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/first-run`;

    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let produces: string[] = [];

    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Get authentication token
   *
   * @param clientId The app-specific id
   */
  public getAuthTokenWithHttpInfo(clientId: string, username: string, password: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/auth-token`;

    let headers = this.getAuthTokenHeaders(username, password);

    // verify required parameter 'clientId' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(clientId)) {
      throw new Error('Required parameter clientId was null or undefined when calling getAuthToken.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    if (!GenericUtils.isUndefinedOrNull(clientId)) {
      formParams = formParams.set('clientId', clientId);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

  /**
   * Return the blocked items
   *
   * @param itemType The item type
   */
  public getBlockedItemsWithHttpInfo(itemType: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/blocked-items`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // verify required parameter 'itemType' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(itemType)) {
      throw new Error('Required parameter itemType was null or undefined when calling getBlockedItems.');
    }
    if (!GenericUtils.isUndefinedOrNull(itemType)) {
      queryParameters = queryParameters.set('itemType', <any>itemType);
    }


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * View current plan
   *
   */
  public getCurrentPlanWithHttpInfo(platform?: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/plan`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];

    if (!GenericUtils.isUndefinedOrNull(platform)) {
      queryParameters = queryParameters.set('platform', <any>platform);
    }

    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  public getCurrentMinutesWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/minutes`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  public getRecurlyHostedLoginTokenWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/recurly/hosted-login-token`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'text/plain'
    ];


    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the favorites
   *
   */
  public getFavoritesWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/favorites`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the payments for user
   *
   */
  public getPlanPaymentsWithHttpInfo(platform?: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/plan-payments`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];

    if (!GenericUtils.isUndefinedOrNull(platform)) {
      queryParameters = queryParameters.set('platform', <any>platform);
    }

    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Return the payments for user
   *
   */
  public getPaymentsHistoryWithHttpInfo(platform?: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/recurly/payments-history`;

    let queryParameters = new HttpParams();
    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];

    if (!GenericUtils.isUndefinedOrNull(platform)) {
      queryParameters = queryParameters.set('platform', <any>platform);
    }

    let requestOptions = {
      headers: headers,
      params: queryParameters
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Exchange the authentication token for a session token
   *
   * @param authToken The auth token
   */
  public getSessionTokenWithHttpInfo(authToken: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/session-token`;

    let headers = this.getSessionTokenHeaders();

    // verify required parameter 'authToken' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(authToken)) {
      throw new Error('Required parameter authToken was null or undefined when calling getSessionToken.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    if (!GenericUtils.isUndefinedOrNull(authToken)) {
      formParams = formParams.set('authToken', authToken);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

  /**
   * Return the settings
   *
   */
  public getSettingsWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/settings`;

    let headers = this.getDefaultHeaders();


    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * View user details
   *
   */
  public getUserDetailsWithHttpInfo(extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/contact-info`;

    let requestOptions = {
      headers: this.getDefaultHeaders()
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Purchase gift plan
   *
   * @param planId The plan id
   * @param paymentToken Recurly payment token (the Gift Plan can only be purchased via Recurly)
   * @param phoneNumber The phone number
   * @param billingAddress The billing address
   * @param country The country
   * @param state The State/Province
   * @param city The city
   * @param zipCode The zip code
   * @param recipientName The recipient name
   * @param recipientEmail The recipient email
   * @param giftDate The gift date
   * @param billingAddressLine2 The billing address line 2 (optional)
   * @param message The message (optional)
   */
  public purchaseGiftPlanWithHttpInfo(planId: number, paymentToken: string, recipientName: string, recipientEmail: string, giftDate: Date, message?: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/gift-plan/purchase`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'planId' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(planId)) {
      throw new Error('Required parameter planId was null or undefined when calling purchaseGiftPlan.');
    }
    // verify required parameter 'recipientName' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(recipientName)) {
      throw new Error('Required parameter recipientName was null or undefined when calling purchaseGiftPlan.');
    }
    // verify required parameter 'recipientEmail' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(recipientEmail)) {
      throw new Error('Required parameter recipientEmail was null or undefined when calling purchaseGiftPlan.');
    }
    // verify required parameter 'giftDate' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(giftDate)) {
      throw new Error('Required parameter giftDate was null or undefined when calling purchaseGiftPlan.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(planId)) {
      formParams = formParams.set('planId', planId.toString());
    }
    if (!GenericUtils.isUndefinedOrNull(paymentToken)) {
      formParams = formParams.set('paymentToken', paymentToken);
    }
    if (!GenericUtils.isUndefinedOrNull(recipientName)) {
      formParams = formParams.set('recipientName', recipientName);
    }
    if (!GenericUtils.isUndefinedOrNull(recipientEmail)) {
      formParams = formParams.set('recipientEmail', recipientEmail);
    }
    if (!GenericUtils.isUndefinedOrNull(giftDate)) {
      let formattedDate = dayjs(giftDate).format("YYYY-MM-DD");
      formParams = formParams.set('giftDate', formattedDate.toString());
    }
    if (!GenericUtils.isUndefinedOrNull(message)) {
      formParams = formParams.set('message', message);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

public updateBillingInfoWithHttpInfo(paymentToken: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/billing-info/update`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'paymentToken' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(paymentToken)) {
      throw new Error('Required parameter paymentToken was null or undefined when calling updateBillingInfo.');
    }

    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];

    if (!GenericUtils.isUndefinedOrNull(paymentToken)) {
      formParams = formParams.set('billingInfoToken', paymentToken);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

  /**
   * Reset password method
   * Send the password reset email (containing the reset token) to a non-logged user
   * @param email The email address of the account
   */
  public resetPasswordWithHttpInfo(email: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/password/reset`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'email' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(email)) {
      throw new Error('Required parameter email was null or undefined when calling resetPassword.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(email)) {
      formParams = formParams.set('email', email);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Set email preferences
   *
   * @param receiveHosWeeklyPlaylist Receive HoS weekly playlist
   * @param receiveHosNewsletter Receive HoS newsletter
   * @param receiveHosDiscountOffers Receive HoS discount offers
   */
  public setEmailPreferencesWithHttpInfo(receiveHosWeeklyPlaylist?: boolean, receiveHosNewsletter?: boolean, receiveHosDiscountOffers?: boolean, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/settings/email-preferences`;

    let headers = this.getDefaultHeaders();

    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(receiveHosWeeklyPlaylist)) {
      formParams = formParams.set('receiveHosWeeklyPlaylist', receiveHosWeeklyPlaylist.toString());
    }
    if (!GenericUtils.isUndefinedOrNull(receiveHosNewsletter)) {
      formParams = formParams.set('receiveHosNewsletter', receiveHosNewsletter.toString());
    }
    if (!GenericUtils.isUndefinedOrNull(receiveHosDiscountOffers)) {
      formParams = formParams.set('receiveHosDiscountOffers', receiveHosDiscountOffers.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Set hemisphere
   *
   * @param value The hemisphere
   */
  public setHemisphereWithHttpInfo(value: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/settings/hemisphere`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'value' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(value)) {
      throw new Error('Required parameter value was null or undefined when calling setHemisphere.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(value)) {
      formParams = formParams.set('value', value);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Set signin content
   *
   * @param value The value to set
   * @param channelId The channel id (required if value&#x3D;choose_channel)
   */
  public setSignInContentWithHttpInfo(value: string, channelId?: number, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/settings/signin-content`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'value' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(value)) {
      throw new Error('Required parameter value was null or undefined when calling setSignInContent.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(value)) {
      formParams = formParams.set('value', value);
    }
    if (!GenericUtils.isUndefinedOrNull(channelId)) {
      formParams = formParams.set('channelId', channelId.toString());
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Set startup page
   *
   * @param value The value to set
   */
  public setStartupPageWithHttpInfo(value: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/settings/startup-page`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'value' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(value)) {
      throw new Error('Required parameter value was null or undefined when calling setStartupPage.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(value)) {
      formParams = formParams.set('value', value);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Update contact info
   *
   * @param firstName The first name
   * @param lastName The last name
   * @param email The email address
   * @param phoneNumber The phone number
   */
  public updateContactInfoWithHttpInfo(firstName: string, lastName: string, email: string, phoneNumber?: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/contact-info`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'firstName' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(firstName)) {
      throw new Error('Required parameter firstName was null or undefined when calling updateContactInfo.');
    }
    // verify required parameter 'lastName' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(lastName)) {
      throw new Error('Required parameter lastName was null or undefined when calling updateContactInfo.');
    }
    // verify required parameter 'email' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(email)) {
      throw new Error('Required parameter email was null or undefined when calling updateContactInfo.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(firstName)) {
      formParams = formParams.set('firstName', firstName);
    }
    if (!GenericUtils.isUndefinedOrNull(lastName)) {
      formParams = formParams.set('lastName', lastName);
    }
    if (!GenericUtils.isUndefinedOrNull(email)) {
      formParams = formParams.set('email', email);
    }
    if (!GenericUtils.isUndefinedOrNull(phoneNumber)) {
      formParams = formParams.set('phoneNumber', phoneNumber);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Update the password
   *
   * @param oldPassword The old password
   * @param newPassword The new password
   */
  public updatePasswordWithHttpInfo(oldPassword: string, newPassword: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    const path = this.getBasePath() + `/account/password/update`;

    let headers = this.getDefaultHeaders();

    // verify required parameter 'oldPassword' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(oldPassword)) {
      throw new Error('Required parameter oldPassword was null or undefined when calling updatePassword.');
    }
    // verify required parameter 'newPassword' is not null or undefined
    if (GenericUtils.isUndefinedOrNull(newPassword)) {
      throw new Error('Required parameter newPassword was null or undefined when calling updatePassword.');
    }


    // to determine the Content-Type header
    let consumes: string[] = [];
    let canConsumeForm = this.canConsumeForm(consumes);
    let useForm = false;
    let formParams = new HttpParams();

    // to determine the Accept header
    let produces: string[] = [];


    if (!GenericUtils.isUndefinedOrNull(oldPassword)) {
      formParams = formParams.set('oldPassword', oldPassword);
    }
    if (!GenericUtils.isUndefinedOrNull(newPassword)) {
      formParams = formParams.set('newPassword', newPassword);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams)
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.PUT, path, requestOptions);
  }

  /**
   * Download the favorites pdf
   *
   */
  public downloadFavorites(observe?: 'body', reportProgress?: boolean) : Observable<Blob> {
    const path = this.getBasePath() + `/account/favorites/download`;

    let queryParameters = new HttpParams();
    let headers = this.getNoLoginHeaders();

    // to determine the Accept header
    let produces: string[] = [
      'application/json'
    ];


    let requestOptions = {
      headers: headers,
      params: queryParameters,
      responseType: 'blob',
      observe: observe,
      reportProgress: reportProgress
    };

    // https://github.com/swagger-api/swagger-codegen/issues/4037
    /*
    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }
    */

    return this.executeHttpRequest(AppConstants.RequestMethods.GET, path, requestOptions);
  }

  /**
   * Activate
   *
   * @param token The token
   * @param captchaToken Captcha token
   * @param type The type
   * @param linkCode The linkCode
   * @param hhid The hhid
   */
  public activateWithHttpInfo(token: string, captchaToken: string, type?: string, linkCode?: string, hhid?: string, extraHttpRequestParams?: any): Observable<HttpResponse<any>> {
    // const path = this.getBasePath() + `/email/hos/action/activate/${token}`;
    const path = this.getBasePathPrefix() + `/email/hos/action/activate/${token}`; // Important: we must use only the basePath prefix (without 'api/v1').

    let headers = this.getDefaultHeaders();

    if (GenericUtils.isUndefinedOrNull(token)) {
      throw new Error('Required parameter token was null or undefined when calling activate.');
    }

    if (GenericUtils.isUndefinedOrNull(captchaToken)) {
      throw new Error('Required parameter captchaToken was null or undefined when calling activate.');
    }

    /*
    const requestBody = {
      captchaToken: captchaToken,
      type: type || null,
      linkCode: linkCode || null,
      hhid: hhid || null,
    };
    */

    let formParams = new HttpParams();

    if (!GenericUtils.isUndefinedOrNull(captchaToken)) {
      formParams = formParams.set('captchaToken', captchaToken);
    }
    if (!GenericUtils.isUndefinedOrNull(type)) {
      formParams = formParams.set('type', type);
    }
    if (!GenericUtils.isUndefinedOrNull(linkCode)) {
      formParams = formParams.set('linkCode', linkCode);
    }
    if (!GenericUtils.isUndefinedOrNull(hhid)) {
      formParams = formParams.set('hhid', hhid);
    }

    let requestOptions = {
      headers: headers,
      body: RequestsUtils.getJsonObjectFromFormParams(formParams),
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    return this.executeHttpRequest(AppConstants.RequestMethods.POST, path, requestOptions);
  }

}
