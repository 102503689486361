import {Injectable} from '@angular/core';
import {HosError} from "../model/HosError";
import construct = Reflect.construct;
import {HosErrorDialog} from "../model/HosErrorDialog";
import {environment} from "../../../environments/environment";
import {SessionToken} from "../model/SessionToken";
import {CoolLocalStorage, CoolSessionStorage} from "@angular-cool/storage";

export class HosLocalError implements HosError {
  errorDescription?: string;
  errorDialog?: HosErrorDialog;
  errorName?: string;
  errorCode?: number;

  constructor(description?: string, code?:number, name?: string) {
    if (description) {
      this.errorDescription = description;
    } else {
      this.errorDescription = "An error happened while loading the data, please check the Internet connection and try again";
    }
    if (!name) {
      this.errorName = "LOCAL_ERROR";
    }
    if (!code) {
      this.errorCode = -100;
    }
  }
}

@Injectable()
export class ApiHelperService {

  private basePath: string;
  private basePathPrefix: string;
  private authToken: string = null;
  private sessionToken: SessionToken = null;
  // TODO manage expiration here? Or wait for the API call to expire for the retry?

  constructor(private localStorage: CoolLocalStorage/*, private sessionStorage: CoolSessionStorage*/) {
    this.basePath = environment.apiConfig.basePath;
    this.basePathPrefix = environment.apiConfig.basePathPrefix;
  }

  public getBasePath(): string {
    return this.basePath;
  }

  public setBasePath(basePath: string) {
    this.basePath = basePath;
  }

  public getBasePathPrefix(): string {
    return this.basePathPrefix;
  }

  public setBasePathPrefix(basePathPrefix: string) {
    this.basePathPrefix = basePathPrefix;
  }

  public getAuthToken(): string {
    let savedAuthToken = this.localStorage.getItem("at");
    if (savedAuthToken) {
      this.authToken = savedAuthToken;
    }
    return this.authToken;
  }

  public setAuthToken(authToken: string) {
    this.authToken = authToken;
    if (authToken!=null) {
      this.localStorage.setItem("at", authToken);
    } else {
      this.localStorage.removeItem("at");
    }
  }

  public getSessionToken(): SessionToken {
    let savedSessionToken = this.localStorage.getObject("st");
    if (savedSessionToken) {
      this.sessionToken = savedSessionToken;
    }
    return this.sessionToken;
  }

  public setSessionToken(sessionToken: SessionToken) {
    this.sessionToken = sessionToken;
    if (sessionToken!=null) {
      this.localStorage.setObject("st", sessionToken);
    } else {
      this.localStorage.removeItem("st");
    }
  }

}
